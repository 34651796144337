import axios from 'axios';
import authHeader from '../auth-header';

const modelName = 'user';
const API_URL_DEFAULT = process.env.REACT_APP_API;

const detail = async (query_string) => {
	return axios.get(`${API_URL_DEFAULT}${modelName}/?${query_string}`, {
		headers: await authHeader(),
	});
};

const update = async (payload) => {
	return axios.post(`${API_URL_DEFAULT}${modelName}`, payload, {
		headers: await authHeader(),
	});
};

export default { detail, update };
