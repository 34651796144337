import React from 'react';
import jwt_decode from 'jwt-decode';
import ReactPlayer from 'react-player';
import { Carousel } from '@trendyol-js/react-carousel';
import PageWrapper from '../layout/PageWrapper/PageWrapper';
import Page from '../layout/Page/Page';
import PageLayoutHeader from '../pages/common/Headers/PageLayoutHeader';
import Alert, { AlertHeading } from '../components/bootstrap/Alert';
import logo1 from '../assets/img/00 1.png';
import logo2 from '../assets/img/FSSC 22000.png';
import logo3 from '../assets/img/SGS ISO 9001.png';
import logo4 from '../assets/img/Halal.png';
import logo5 from '../assets/img/FDA.png';
import logoBrand1 from '../assets/img/logoBrand1.png';
import logoBrand2 from '../assets/img/logoBrand2.png';
import logoBrand3 from '../assets/img/logoBrand3.png';
import logoBrand4 from '../assets/img/logoBrand4.png';
import logoBrand5 from '../assets/img/logoBrand5.png';
import Icon from '../components/icon/Icon';

const getAccToken = JSON.parse(localStorage.getItem('accessToken', {}));
const decodeToken = getAccToken ? jwt_decode(getAccToken.accessToken) : null;

const Dashboard = () => {
	return (
		<PageWrapper title='Home'>
			<PageLayoutHeader />
			<Page container='fluid'>
				<div className='row'>
					<div className='col-12'>
						<Alert
							icon='Verified'
							isLight
							color='success'
							rounded={2}
							borderWidth={0}
							className='shadow-3d-primary'>
							<AlertHeading tag='h2' className='h4'>
								Welcome{' '}
								{decodeToken &&
									decodeToken.details.hris_org_tree.current_person.person_name}
							</AlertHeading>
							<span>Simplicity your work by using our apps!</span>
						</Alert>
					</div>
				</div>
				<div className='row justify-content-center px-4'>
					<img alt='logo1' src={logo1} style={{ width: '30%' }} />
					<p className='mt-5'>
						PT Lautan Natural Krimerindo is a leading company of food and beverage
						ingredients, specialized in spray dried ingredients and powder premixes. We
						always create value by only using the finest quality ingredients processed
						in the state of the art facility to deliver the best products for customers.
					</p>
					<div className='d-flex flex-col justify-content-around mt-5'>
						<div className='d-flex justify-content-center'>
							<img alt='logo2' src={logo2} style={{ width: '30%' }} />
						</div>
						<div className='d-flex justify-content-center'>
							<img alt='logo3' src={logo3} style={{ width: '30%' }} />
						</div>
						<div className='d-flex justify-content-center'>
							<img alt='logo4' src={logo4} style={{ width: '30%' }} />
						</div>
						<div className='d-flex justify-content-center'>
							<img alt='logo5' src={logo5} style={{ width: '30%' }} />
						</div>
					</div>
					<div className='row mt-5' style={{ height: '500px' }}>
						<ReactPlayer
							width='100%'
							height='100%'
							url='https://www.youtube.com/watch?v=TkVDeep_cc8'
						/>
					</div>

					<div className='row mt-5'>
						<h4
							style={{ color: '#1460B0', fontWeight: 'bold' }}
							className='text-center mb-5'>
							Selection Of Brands
						</h4>
						<div className='container' style={{ marginTop: '15px', padding: '0 30px' }}>
							<div className='row'>
								<Carousel
									className='exampleCarousel1'
									show={4}
									slide={1}
									swiping='true'
									responsive='true'
									rightArrow=<Icon
										icon='ArrowRight'
										size='2x'
										style={{ cursor: 'pointer' }}
									/>
									leftArrow=<Icon
										icon='ArrowLeft'
										size='2x'
										style={{ cursor: 'pointer' }}
									/>>
									<div className='col'>
										<img src={logoBrand1} alt='bg-1' style={{ width: '55%' }} />
									</div>
									<div className='col'>
										<img src={logoBrand2} alt='bg-1' style={{ width: '55%' }} />
									</div>
									<div className='col'>
										<img src={logoBrand3} alt='bg-1' style={{ width: '55%' }} />
									</div>
									<div className='col'>
										<img src={logoBrand4} alt='bg-1' style={{ width: '55%' }} />
									</div>
									<div className='col'>
										<img src={logoBrand5} alt='bg-1' style={{ width: '55%' }} />
									</div>
								</Carousel>
							</div>
						</div>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default Dashboard;
