import axios from 'axios';
import authHeader from '../auth-header';

const API_URL_DEFAULT = process.env.REACT_APP_API;

const create = async (payload) => {
	return axios.post(`${API_URL_DEFAULT}instruction`, payload, { headers: await authHeader() });
};

const read = async (query) => {
	return axios.get(`${API_URL_DEFAULT}instruction`, {
		headers: await authHeader(),
		params: query,
	});
};

const upload = async (payload) => {
	return axios.post(`${API_URL_DEFAULT}instruction/upload`, payload, {
		headers: await authHeader(),
	});
};

export default { create, read, upload };
