import transporterService from '../../services/afif/transporter.service';

const login = (userId, uniqueCode) => {
	return transporterService.login(userId, uniqueCode).then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};

const loginByDONumber = (doNumber) => {
	return transporterService.loginByDONumber(doNumber).then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};

const getCurrentTransporter = () => {
	return transporterService.getCurrentTransporter().then(
		(response) => {
			return Promise.resolve(response.data, 'Successfully retrieved current transporter');
		},
		(error) => {
			const message =
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};

const updateTracking = (payload) => {
	return transporterService.updateTracking(payload).then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};

const updateCustomer = (payload) => {
	return transporterService.updateCustomer(payload).then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};

export default { login, loginByDONumber, getCurrentTransporter, updateTracking, updateCustomer };
