import axios from 'axios';
import authHeader from '../auth-header';

const API_URL = process.env.REACT_APP_API;

const registerVendor = async (payload) => {
	return axios.post(`${API_URL}register-vendor`, payload, { headers: await authHeader() });
};

const verify = async (payload) => {
	return axios.post(`${API_URL}register-vendor/verify`, payload, { headers: await authHeader() });
};

export default { registerVendor, verify };
