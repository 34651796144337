import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { useFormik } from 'formik';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import Card, { CardBody } from '../../components/bootstrap/Card';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import Input from '../../components/bootstrap/forms/Input';
import Button from '../../components/bootstrap/Button';
import LogoLNK from '../../components/LogoLNK';
import useDarkMode from '../../hooks/useDarkMode';
import AuthModule from '../../modules/AuthModule';
import Alert, { AlertHeading } from '../../components/bootstrap/Alert';
import UserModule from '../../modules/sodiq/UserModule';
import showNotification from '../../components/extras/showNotification';
import { getRequester } from '../../helpers/helpers';
import Spinner from '../../components/bootstrap/Spinner';

const validate = (values) => {
	const { username } = getRequester();
	const errors = {};
	if (!values.new_password || values.new_password === '') {
		const key = 'new_password';
		errors[key] = 'Required';
	}
	if (!values.confirm_password || values.confirm_password === '') {
		const key = 'confirm_password';
		errors[key] = 'Required';
	}

	if (values.new_password) {
		if (values.new_password === username) {
			const key = 'new_password';
			errors[key] = 'Password cannot be the same username';
		}
	}

	if (values.new_password && values.confirm_password) {
		if (values.new_password !== values.confirm_password) {
			const key = 'confirm_password';
			errors[key] = 'Confirm Password Not Valid';
		}
	}
	return errors;
};

// eslint-disable-next-line react/prop-types
const LoginHeader = (dt) => {
	const { submit, count } = dt;
	return (
		<>
			{!submit && (
				<>
					<Alert
						style={{ marginTop: '15px' }}
						isLight
						color='danger'
						rounded={2}
						borderWidth={0}
						className='shadow-3d-primary'>
						<AlertHeading tag='h2' className='h4'>
							Warning
						</AlertHeading>
						<span>Username and Password is Match. Please change your password!</span>
					</Alert>
					<div className='text-center h4 text-muted mb-5'>
						Change Password to continue!
					</div>
				</>
			)}

			{submit && (
				<>
					<Alert
						style={{ marginTop: '15px' }}
						isLight
						color='success'
						rounded={2}
						borderWidth={0}
						className='shadow-3d-primary'>
						<AlertHeading tag='h2' className='h4'>
							Success
						</AlertHeading>
						<span>
							Change password has been updated successfully, Please login with new
							password!
						</span>
					</Alert>
					<div className='text-center h4 text-muted mb-2'>{`Redirecting (${count}s)`}</div>
					<div
						role='presentation'
						className='text-center h5 mb-5'
						onClick={() => AuthModule.logout()}
						style={{ cursor: 'pointer', color: '#5f78fb' }}>
						Login
					</div>
				</>
			)}
		</>
	);
};

const ChangePassword = () => {
	const [submit, setSubmit] = useState(false);
	const [count, setCount] = useState(null);
	const [loading, setLoading] = useState(false);
	const [initialValues, setInitialValues] = useState(null);
	const { darkModeStatus } = useDarkMode();
	const { username } = getRequester();

	useEffect(() => {
		fecthData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (submit) {
			const rotationInterval = setInterval(() => {
				if (count === 0) {
					setCount(0);
				} else {
					setCount(count - 1);
				}
			}, 1000);
			return () => {
				clearInterval(rotationInterval);
			};
		}
		return {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [count, submit]);

	const fecthData = () => {
		const params = {
			username,
		};
		UserModule.detail(new URLSearchParams(params))
			.then((res) => {
				setInitialValues(res);
			})
			.catch((err) => {
				showNotification('Warning!', err, 'danger');
			});
	};

	const handleSubmit = (values) => {
		setLoading(true);
		values._id = initialValues._id;
		UserModule.update(values)
			.then(() => {
				setLoading(false);
				setSubmit(true);
				setCount(10);
				setTimeout(() => {
					AuthModule.logout();
					setSubmit(false);
				}, 10000);
			})
			.catch((err) => {
				showNotification('Warning!', err, 'danger');
			});
	};

	const formik = useFormik({
		initialValues: {
			new_password: '',
			confirm_password: '',
		},
		validate,
		onSubmit: (values) => {
			// eslint-disable-next-line no-alert
			handleSubmit(values);
		},
	});

	return (
		<PageWrapper title='Change Password' className={classNames('bg-info')}>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card
							className='shadow-3d-dark'
							data-tour='login-page'
							stretch
							tag='form'
							noValidate
							onSubmit={formik.handleSubmit}>
							<CardBody>
								<div className='text-center my-2'>
									<Link
										to='/'
										className={classNames(
											'text-decoration-none  fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}>
										<LogoLNK width={200} />
									</Link>
								</div>
								<LoginHeader submit={submit} count={count} />
								{!submit && (
									<>
										<div className='col-12 mb-3'>
											<FormGroup
												id='new_password'
												isFloating
												label='New Password'>
												<Input
													type='password'
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													name='new_password'
													isValid={formik.isValid}
													invalidFeedback={formik.errors.new_password}
													value={formik.values.new_password}
													isTouched={formik.touched.new_password}
													readOnly={loading}
													autoComplete='off'
												/>
											</FormGroup>
										</div>
										<div className='col-12 mb-3'>
											<FormGroup
												id='confirm_password'
												isFloating
												label='Confirm Password'>
												<Input
													type='password'
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													name='confirm_password'
													isValid={formik.isValid}
													invalidFeedback={formik.errors.confirm_password}
													value={formik.values.confirm_password}
													isTouched={formik.touched.confirm_password}
													readOnly={loading}
													autoComplete='off'
												/>
											</FormGroup>
										</div>
										<div className='col-12'>
											{loading ? (
												<Button
													type='submit'
													color='warning'
													className='w-100 py-3'>
													<Spinner color='dark' size='1.2rem' /> Please
													wait
												</Button>
											) : (
												<Button
													type='submit'
													color='warning'
													className='w-100 py-3'>
													Submit
												</Button>
											)}
										</div>
									</>
								)}
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default ChangePassword;
