import jwt_decode from 'jwt-decode';

export function test() {
	return null;
}

export const isEmptyObject = (obj) => {
	return obj == null || !Object.keys(obj).length;
};

export const isEmptyArray = (arr) => {
	return arr == null || !arr.length;
};

export const getRequester = () => {
	const acToken = JSON.parse(localStorage.getItem('accessToken'));
	const photo = JSON.parse(localStorage.getItem('photo'));
	const decodedToken = jwt_decode(acToken.accessToken);
	const { details, username } = decodedToken;
	return {
		username,
		email: details.hris_org_tree?.current_person?.email,
		department: details.hris_org_tree?.current_person?.nama_department,
		person_name: details.hris_org_tree?.current_person?.person_name,
		position_name: details.hris_org_tree?.current_person?.nama_posisi,
		photo,
	};
};
export const getRouting = (data) => {
	const rawRoute = Object.keys(data)
		.map((item) => {
			if (data[item].subMenu) {
				return Object.keys(data[item].subMenu).map((sb) => {
					return {
						path: data[item].subMenu[sb].path,
						element: data[item].subMenu[sb].element,
					};
				});
			}
			return null;
		})
		.filter((e) => e);
	const merge = [].concat(...rawRoute);
	return merge;
};
export function getOS() {
	const { userAgent } = window.navigator;
	const { platform } = window.navigator;
	const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
	const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
	const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
	let os = null;

	if (macosPlatforms.indexOf(platform) !== -1) {
		os = 'MacOS';
	} else if (iosPlatforms.indexOf(platform) !== -1) {
		os = 'iOS';
	} else if (windowsPlatforms.indexOf(platform) !== -1) {
		os = 'Windows';
	} else if (/Android/.test(userAgent)) {
		os = 'Android';
	} else if (!os && /Linux/.test(platform)) {
		os = 'Linux';
	}

	document.documentElement.setAttribute('os', os);
	return os;
}

export const getColumnName = (value) => {
	const ordA = 'A'.charCodeAt(0);
	const ordZ = 'Z'.charCodeAt(0);
	const len = ordZ - ordA + 1;

	let s = '';
	while (value >= 0) {
		s = String.fromCharCode((value % len) + ordA) + s;
		value = Math.floor(value / len) - 1;
	}

	return s;
};

export const hasNotch = () => {
	/**
	 * For storybook test
	 */
	const storybook = window.location !== window.parent.location;
	const iPhone = /iPhone/.test(navigator.userAgent) && !window.MSStream;
	const aspect = window.screen.width / window.screen.height;
	const aspectFrame = window.innerWidth / window.innerHeight;
	return (
		(iPhone && aspect.toFixed(3) === '0.462') ||
		(storybook && aspectFrame.toFixed(3) === '0.462')
	);
};

export const mergeRefs = (refs) => {
	return (value) => {
		refs.forEach((ref) => {
			if (typeof ref === 'function') {
				ref(value);
			} else if (ref != null) {
				ref.current = value;
			}
		});
	};
};

export const randomColor = () => {
	const array = new Uint32Array(10);
	const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger'];

	const color = Math.floor(window.self.crypto.getRandomValues(array) * colors.length);

	return colors[color];
};

export const priceFormat = (price) => {
	return price.toLocaleString('en-US', {
		style: 'currency',
		currency: 'USD',
	});
};

export const average = (array) => array.reduce((a, b) => a + b) / array.length;

export const percent = (value1, value2) => ((value1 / value2 - 1) * 100).toFixed(2);

export const getFirstLetter = (text, letterCount = 2) =>
	text
		.toUpperCase()
		.match(/\b(\w)/g)
		.join('')
		.substring(0, letterCount);

export const debounce = (func, wait = 1000) => {
	let timeout;

	return function executedFunction(...args) {
		const later = () => {
			clearTimeout(timeout);
			func(...args);
		};

		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
	};
};

export const convertToRupiah = (angka) => {
	return new Intl.NumberFormat('id-ID', {
		// style: 'currency',
		currency: 'IDR',
		minimumFractionDigits: 0,
		maximumFractionDigits: 0,
	})
		.format(angka)
		.replace(/\D00(?=\D*$)/, '');
};

export const convertToAngka = (rupiah) => {
	return parseInt(rupiah.replace(/,.*|[^0-9]/g, ''), 10);
};

export const convertToTextNumber = (angka) => {
	return new Intl.NumberFormat('id-ID', {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	}).format(angka);
};

export const convertToRupiahEng = (angka) => {
	if (angka && !Number.isNaN(Number(angka))) {
		return new Intl.NumberFormat('en-US', {
			minimumFractionDigits: 0,
			maximumFractionDigits: 0,
		})
			.format(angka)
			.replace(/\D00(?=\D*$)/, '');
	}
	return '';
};

export const validateEmail = (email) => {
	return String(email)
		.toLowerCase()
		.match(
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
		);
};

export const convertToFormData = (values, fileKeys = []) => {
	const formData = new FormData();
	Object.keys(values).forEach((key) => {
		if (values[key] && fileKeys.includes(key)) {
			const splitName = values[key].name.split('.');
			formData.append('files', values[key], `${key}.${splitName[splitName.length - 1]}`);
		} else formData.append(key, values[key]);
	});

	return formData;
};

export const mimeToExtension = (mimeType) => {
	const extensionMap = {
		'image/jpeg': 'jpg',
		'image/png': 'png',
		'application/pdf': 'pdf',
	};

	return extensionMap[mimeType] || '';
};
