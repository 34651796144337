import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import IFrame from 'react-iframe';
import PropTypes from 'prop-types';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../bootstrap/Modal';
import FileModule from '../../modules/FileModule';
import Spinner from '../bootstrap/Spinner';
import showNotification from '../extras/showNotification';
import Card, { CardBody } from '../bootstrap/Card';

export const DocumentViewModal = ({
	isOpen,
	setIsOpen,
	id,
	title,
	titleId,
	isStaticBackdrop,
	isScrollable,
	isCentered,
	size,
	fullScreen,
	isAnimation,
	onedrive_id,
	withoutDatabase,
	...props
}) => {
	const [document, setDocument] = useState('');

	return (
		<Modal
			isOpen={isOpen}
			setIsOpen={setIsOpen}
			id={id}
			titleId={titleId}
			isStaticBackdrop={isStaticBackdrop}
			isScrollable={isScrollable}
			isCentered={isCentered}
			size={size}
			fullScreen={fullScreen}
			isAnimation={isAnimation}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}>
			<ModalHeader setIsOpen={setIsOpen} className={classNames('p-4')}>
				<ModalTitle id={titleId}>{title}</ModalTitle>
			</ModalHeader>
			<ModalBody
				style={{
					display: 'flex',
					height: `${window.innerHeight - 100}px`,
					alignItems: 'center',
					justifyContent: 'center',
				}}>
				{onedrive_id || document ? (
					<DocumentView
						onedrive_id={onedrive_id}
						document={document}
						setDocument={setDocument}
						withoutDatabase={withoutDatabase}
					/>
				) : (
					<div>Document Not Found</div>
				)}
			</ModalBody>
		</Modal>
	);
};

DocumentViewModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
	/**
	 * ModalHeader, ModalBody and ModalFooter
	 */
	id: PropTypes.string,
	/**
	 * For Accessibility
	 */
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	titleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	/**
	 * When backdrop is set to static, the modal will not close when clicking outside it.
	 */
	isStaticBackdrop: PropTypes.bool,
	/**
	 * When modals become too long for the user’s viewport or device, they scroll independent of the page itself.
	 */
	isScrollable: PropTypes.bool,
	/**
	 * vertically center the modal
	 */
	isCentered: PropTypes.bool,
	/**
	 *  These sizes kick in at certain breakpoints to avoid horizontal scrollbars on narrower viewports.
	 */
	size: PropTypes.oneOf([null, 'sm', 'lg', 'xl']),
	/**
	 * Another override is the option to pop up a modal that covers the user viewport.
	 */
	fullScreen: PropTypes.oneOfType([
		PropTypes.oneOf(['sm', 'md', 'lg', 'xl', 'xxl']),
		PropTypes.bool,
	]),
	isAnimation: PropTypes.bool,
	/**
	 *
	 */
	onedrive_id: PropTypes.string,
	withoutDatabase: PropTypes.bool,
};
DocumentViewModal.defaultProps = {
	id: null,
	title: 'Modal',
	titleId: 'view',
	isStaticBackdrop: false,
	isScrollable: false,
	isCentered: false,
	size: null,
	fullScreen: false,
	isAnimation: true,
	onedrive_id: null,
	withoutDatabase: false,
};

const DocumentView = ({ onedrive_id, document, setDocument, withoutDatabase }) => {
	const [loading, setLoading] = useState(false);
	const [content, setContent] = useState('');

	useEffect(() => {
		if (document) {
			setContent(document);
		} else if (onedrive_id) {
			fetchData(onedrive_id);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [onedrive_id, document]);

	const fetchData = async (id) => {
		setLoading(true);

		const query = { onedrive_id: id };

		return withoutDatabase
			? FileModule.directRead(query)
					.then((response) => {
						const res = `data:${response.mimetype};base64,${response.file}`;
						setContent(res);
						setDocument(document);
					})
					.catch(() => {
						showNotification('Information!', 'Failed preview template', 'danger');
					})
					.finally(() => {
						setLoading(false);
					})
			: FileModule.read(query)
					.then((response) => {
						const res = `data:${response.mimetype};base64,${response.file}`;
						setContent(res);
						setDocument(document);
					})
					.catch(() => {
						showNotification('Information!', 'Failed preview template', 'danger');
					})
					.finally(() => {
						setLoading(false);
					});
	};

	return loading ? (
		<Spinner color='info' size='12rem' />
	) : (
		<Card shadow='none' stretch>
			<CardBody isScrollable>
				<IFrame
					src={content}
					width='1060px'
					height={`${window.innerHeight - 190}px`}
					id='myId'
					className='myClassname'
					display='initial'
					position='relative'
				/>
			</CardBody>
		</Card>
	);
};

DocumentView.propTypes = {
	onedrive_id: PropTypes.string,
	document: PropTypes.string,
	setDocument: PropTypes.func,
	withoutDatabase: PropTypes.bool,
};
DocumentView.defaultProps = {
	onedrive_id: null,
	document: null,
	setDocument: () => {},
	withoutDatabase: false,
};

export default DocumentView;
