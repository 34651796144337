import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import Card, { CardBody } from '../../components/bootstrap/Card';
import DocumentView from '../../components/custom/DocumentView';
import Page from '../../layout/Page/Page';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import InstructionModule from '../../modules/bakti/InstructionModule';
import PageLayoutHeader from '../../pages/common/Headers/PageLayoutHeader';

const ProductListing = () => {
	const [data, setData] = useState(null);

	useEffect(() => {
		// user tax / ap
		const type = 'wit_vendor_product_listing';

		fetchData(type);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fetchData = async (type) => {
		const query = { type, status: 'active' };

		return InstructionModule.read(query)
			.then((response) => {
				if (response.foundData?.length > 0) {
					setData(response.foundData?.at(0));
				} else {
					setData(null);
				}
			})
			.catch(() => {
				setData(null);
			})
			.finally(() => {});
	};

	return (
		<PageWrapper title='Instruction'>
			<PageLayoutHeader />
			<Page container='fluid'>
				<Card stretch>
					<CardBody
						style={{
							display: 'flex',
							height: `${window.innerHeight - 100}px`,
							alignItems: 'center',
							justifyContent: 'center',
						}}>
						{data ? (
							<DocumentView onedrive_id={data?.onedrive_id} />
						) : (
							<div style={{ fontSize: 'calc(3rem + 3vw)' }}>
								<p>
									<span className='text-primary fw-bold me-1'>Document</span> Not
									found
								</p>
							</div>
						)}
					</CardBody>
				</Card>
			</Page>
		</PageWrapper>
	);
};

ProductListing.propTypes = {};
ProductListing.defaultProps = {};

export default ProductListing;
