import axios from 'axios';
import authHeader from '../auth-header';

const API_URL = process.env.REACT_APP_API;

const login = async (userId, uniqueCode) => {
	return axios
		.post(
			`${API_URL}transporter/signin`,
			{ userId, uniqueCode },
			{ headers: await authHeader() },
		)
		.then(async (response) => {
			return response;
		});
};

const loginByDONumber = async (doNumber) => {
	return axios.post(
		`${API_URL}transporter/signin/by-do-number`,
		{ doNumber },
		{
			headers: await authHeader(),
		},
	);
};

const getCurrentTransporter = async () => {
	return axios
		.get(`${API_URL}transporter/me`, { headers: await authHeader() })
		.then(async (response) => {
			return response;
		});
};

const updateTracking = async (payload) => {
	return axios
		.put(`${API_URL}transporter/tracking`, payload, { headers: await authHeader() })
		.then(async (response) => {
			return response;
		});
};

const updateCustomer = async (payload) => {
	return axios
		.put(`${API_URL}transporter/customer`, payload, { headers: await authHeader() })
		.then(async (response) => {
			return response;
		});
};

export default {
	login,
	loginByDONumber,
	getCurrentTransporter,
	updateCustomer,
	updateTracking,
};
