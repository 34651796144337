import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import classNames from 'classnames';
import jwt_decode from 'jwt-decode';
import { useFormik } from 'formik';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import Page from '../../../../layout/Page/Page';
import Card, { CardBody } from '../../../../components/bootstrap/Card';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Input from '../../../../components/bootstrap/forms/Input';
import Button from '../../../../components/bootstrap/Button';
import LogoLNK from '../../../../components/LogoLNK';
import useDarkMode from '../../../../hooks/useDarkMode';
import AuthModule from '../../../../modules/AuthModule';
import TransporterModule from '../../../../modules/afif/TransporterModule';
import showNotification from '../../../../components/extras/showNotification';
import './LoginTransporter.scss';
import Modal, { ModalBody } from '../../../../components/bootstrap/Modal';
import Spinner from '../../../../components/bootstrap/Spinner';

// eslint-disable-next-line react/prop-types
const LoginHeader = ({ className }) => {
	return (
		<div className={classNames('col-sm-12', className)}>
			<div className='text-center h2 fw-bold mt-5'>Formulir Perjalanan</div>
			<div className='text-center h5 text-muted mb-5'>
				Selamat Datang
				<br />
				Silahkan Login untuk Melanjutkan
			</div>
		</div>
	);
};

LoginHeader.propTypes = {
	className: PropTypes.string,
};
LoginHeader.defaultProps = {
	className: null,
};

const Copyright = () => {
	return (
		<footer className='footer my-3'>
			Copyright © 2023 PT Lautan Natural Krimerindo. All rights reserved
		</footer>
	);
};

const LoginModal = ({ isOpen, setIsOpen, size }) => {
	const { darkModeStatus } = useDarkMode();
	const [loading, setLoading] = useState(false);

	const formik = useFormik({
		initialValues: { doNumber: '' },
		validationSchema: Yup.object().shape({
			doNumber: Yup.string()
				.matches(/^\d+$/, 'Only numbers are allowed')
				.required('Required'),
		}),
		onReset: () => {
			setIsOpen(false);
		},
		onSubmit: (values) => {
			setLoading(true);

			TransporterModule.loginByDONumber(values.doNumber)
				.then((res) => {
					if (res.transporterToken) {
						localStorage.setItem('transporterToken', JSON.stringify(res));
						window.location.reload();
					}
				})
				.catch((err) => {
					showNotification('Warning!', err, 'danger');
				})
				.finally(() => {
					setLoading(false);
				});
		},
	});

	return (
		<Modal
			titleId='form-login'
			isOpen={isOpen}
			setIsOpen={formik.resetForm}
			size={size}
			isFocus={false}
			isCentered
			enableEscape={false}
			isStaticBackdrop>
			<ModalBody>
				<div className='row' tag='form' noValidate onSubmit={formik.handleSubmit}>
					<div className='col-sm-12 text-center py-1'>
						<LogoLNK width={140} />
					</div>
					<LoginHeader className='py-1' />
					<div className='col-sm-12'>
						<FormGroup id='doNumber' label='DO Number' isFloating>
							<Input
								autoComplete='off'
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								isValid={formik.isValid}
								invalidFeedback={formik.errors.doNumber}
								value={formik.values.doNumber}
								isTouched={formik.touched.doNumber}
								onKeyDown={(e) => {
									if (e.key === 'Enter') {
										formik.handleSubmit(e);
									}
								}}
							/>
						</FormGroup>
					</div>
					<div className='col-sm-12 pt-3 pb-1'>
						{loading ? (
							<Button
								color='warning'
								isLight={darkModeStatus}
								className='mx-1 float-end'>
								<Spinner color='dark' inButton='onlyIcon' isGrow isSmall /> Login
							</Button>
						) : (
							<Button
								icon='Login'
								color='warning'
								type='submit'
								isLight={darkModeStatus}
								className='mx-1 float-end'
								onClick={formik.handleSubmit}>
								Login
							</Button>
						)}

						<Button
							isLink
							color='primary'
							type='reset'
							className='mx-1 float-start'
							onClick={formik.resetForm}>
							Cancel
						</Button>
					</div>
				</div>
			</ModalBody>
		</Modal>
	);
};

LoginModal.propTypes = {
	isOpen: PropTypes.bool,
	setIsOpen: PropTypes.func,
	size: PropTypes.oneOf([null, 'sm', 'lg', 'xl', 'xxl']),
};
LoginModal.defaultProps = {
	isOpen: false,
	setIsOpen: () => false,
	size: null,
};

const transporterToken = localStorage.getItem('transporterToken');
const appToken = localStorage.getItem('appToken');
const localName = process.env.REACT_APP_NAME;
const decodeToken = appToken ? jwt_decode(appToken) : null;

const Login = () => {
	const { darkModeStatus } = useDarkMode();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);

	const [openLogin, setOpenLogin] = useState(false);

	useEffect(() => {
		if (!loading && !transporterToken) {
			return AuthModule.generateToken();
		}
		const parseTransporterToken = transporterToken && JSON.parse(transporterToken);
		if (parseTransporterToken && decodeToken && decodeToken.name === localName) {
			navigate('/transporter/home');
		}

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading]);

	const handleLogin = (values) => {
		if (values.userId && values.uniqueCode) {
			setLoading(true);
			TransporterModule.login(values.userId, values.uniqueCode)
				.then(async (res) => {
					if (res.transporterToken) {
						localStorage.setItem('transporterToken', JSON.stringify(res));
						window.location.reload();
					}
				})
				.catch((err) => {
					showNotification('Warning!', err, 'danger');
				})
				.finally(() => {
					setLoading(false);
				});
		}
	};

	const formik = useFormik({
		initialValues: {
			userId: '',
			uniqueCode: '',
		},
		validationSchema: Yup.object({
			userId: Yup.string().required('Required'),
			uniqueCode: Yup.string().required('Required'),
		}),
		onSubmit: (values) => {
			handleLogin(values);
		},
	});

	return (
		<PageWrapper title='Login'>
			<Page>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card
							className='shadow-3d-dark'
							data-tour='login-page'
							stretch
							tag='form'
							noValidate
							onSubmit={formik.handleSubmit}>
							<CardBody>
								<div className='text-center my-2'>
									<Link
										to='/'
										className={classNames(
											'text-decoration-none  fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}>
										<LogoLNK width={140} />
									</Link>
								</div>
								<LoginHeader />
								<div className='col-12 mb-3'>
									<FormGroup
										id='userId'
										autoComplete='off'
										isFloating
										label='No. Hp'>
										<Input
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											name='userId'
											isValid={formik.isValid}
											invalidFeedback={formik.errors.userId}
											value={formik.values.userId}
											isTouched={formik.touched.userId}
											readOnly={loading}
										/>
									</FormGroup>
								</div>
								<div className='col-12 mb-3'>
									<div className='row'>
										<div className='col-10'>
											<FormGroup
												id='uniqueCode'
												isFloating
												autoComplete='off'
												label='Kode Unik'>
												<Input
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													name='uniqueCode'
													isValid={formik.isValid}
													invalidFeedback={formik.errors.uniqueCode}
													value={formik.values.uniqueCode}
													isTouched={formik.touched.uniqueCode}
													readOnly={loading}
												/>
											</FormGroup>
										</div>
										<div className='col-2'>
											<Button
												isOutline
												icon='qr-code'
												type='button'
												size='lg'
												color='primary'
											/>
										</div>
									</div>
								</div>
								<div className='col-12'>
									<Button
										icon='Login'
										type='submit'
										color='primary'
										className='w-100 py-3'>
										Login
									</Button>
								</div>
								<div className='col-12 mt-3 text-center text-muted'>OR</div>
								<div className='col-12 mt-3'>
									<Button
										isOutline
										color={darkModeStatus ? 'light' : 'dark'}
										className={classNames('w-100 py-3', {
											'border-light': !darkModeStatus,
											'border-dark': darkModeStatus,
										})}
										onClick={() => setOpenLogin(true)}>
										Sign in with DO Number
									</Button>
								</div>
							</CardBody>
						</Card>
					</div>
				</div>
				<Copyright />

				<LoginModal isOpen={openLogin} setIsOpen={setOpenLogin} />
			</Page>
		</PageWrapper>
	);
};

export default Login;
