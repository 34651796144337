import axios from 'axios';
import authHeader from '../auth-header';

const modelName = 'profile';
const API_URL_DEFAULT = process.env.REACT_APP_API;

const detail = async (query_string) => {
	return axios.get(`${API_URL_DEFAULT}${modelName}/?${query_string}`, {
		headers: await authHeader(),
	});
};

const update = async (payload) => {
	return axios.post(`${API_URL_DEFAULT}${modelName}`, payload, {
		headers: await authHeader(),
	});
};

const getBank = async (query_string) => {
	return axios.get(`${API_URL_DEFAULT}${modelName}/get-bank?${query_string}`, {
		headers: await authHeader(),
	});
};

const getPaymentMethod = async (query_string) => {
	return axios.get(`${API_URL_DEFAULT}${modelName}/get-payment-method?${query_string}`, {
		headers: await authHeader(),
	});
};

const getPaymentTerms = async (query_string) => {
	return axios.get(`${API_URL_DEFAULT}${modelName}/get-payment-terms?${query_string}`, {
		headers: await authHeader(),
	});
};

const preview = async (query_string) => {
	return axios.get(`${API_URL_DEFAULT}${modelName}/preview?${query_string}`, {
		headers: await authHeader(),
	});
};

export default { detail, update, getBank, getPaymentMethod, getPaymentTerms, preview };
