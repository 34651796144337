import FileService from '../services/file.service';

const read = (query) => {
	return FileService.read(query).then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.message && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};
 
const directRead = (query) => {
	return FileService.directRead(query).then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.message && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};
 
const readByID = (onedrive_id) => {
	return FileService.readByID(onedrive_id).then( 
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message = 
				(error.response && error.response.data && error.response.data.message) || 
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};
 
export default { read, directRead,readByID }; 
