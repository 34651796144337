import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Login from '../../views/Login';
import LoginTransporter from '../../views/afif/driver-transporter/login/LoginTransporter';
import PrivateRoute from '../../components/PrivateRoute';
import PrivateTransporterRoute from '../../components/PrivateTransporterRoute';
import Dashboard from '../../views/Dashboard';
import DashboardTransporter from '../../views/afif/driver-transporter/dashboard/DashboardTransporter';
import InstructionView from '../../views/bakti/InstructionView';
import InstructionProductListing from '../../views/handri/ProductListing';
import PAGE_404 from '../../pages/presentation/auth/Page404';
import { getRouting } from '../../helpers/helpers';
import contents from '../../routes/customRoutes';
import Profile from '../../views/sodiq/Profile';
import ChangePassword from '../../views/sodiq/ChangePassword';
import RegisterVendor from '../../views/sodiq/RegisterVendor';

const menuDB = JSON.parse(localStorage.getItem('menu'));
const presentationDraft = menuDB && getRouting(menuDB);
// get component first
const getComponent =
	presentationDraft &&
	presentationDraft.map((az) => {
		// find component on contents
		const findComponent = contents.filter((ct) => ct.index === az.element);
		const defaultComponent = findComponent.length > 0 ? findComponent[0].element : PAGE_404;
		const objC = { element: defaultComponent, path: az.path };
		return objC;
	});
const presentation =
	getComponent &&
	getComponent.map((it) => {
		const objRet = {
			element: it && it.element ? it.element : null,
			path: it && it.path ? it.path : null,
			key: `${it && it.path}pres`,
		};
		return objRet;
	});

const accessToken = JSON.parse(localStorage.getItem('accessToken'));
const transporterToken = JSON.parse(localStorage.getItem('transporterToken'));

const ContentRoutes = () => {
	return (
		<Routes>
			<Route exact path='/login' element={<Login />} />
			<Route exact path='/login/:id/:token' element={<Login />} />
			<Route exact path='/register' element={<RegisterVendor />} />
			<Route
				exact
				path='/home'
				element={
					<PrivateRoute>
						<Dashboard />
					</PrivateRoute>
				}
			/>
			<Route
				exact
				path='/instruction'
				element={
					<PrivateRoute>
						<InstructionView />
					</PrivateRoute>
				}
			/>
			<Route
				exact
				path='/instruction-product-listing'
				element={
					<PrivateRoute>
						<InstructionProductListing />
					</PrivateRoute>
				}
			/>

			<Route exact path='/transporter/login' element={<LoginTransporter />} />
			<Route
				exact
				path='/transporter/home'
				element={
					<PrivateTransporterRoute>
						<DashboardTransporter />
					</PrivateTransporterRoute>
				}
			/>

			{accessToken &&
				presentation.map((page) => (
					// eslint-disable-next-line react/jsx-props-no-spreading
					<Route exact key={page.path} {...page} />
				))}
			<Route
				exact
				path='/'
				element={
					accessToken ? <Navigate to='/home' replace /> : <Navigate to='/login' replace />
				}
			/>

			<Route
				exact
				path='/transporter'
				element={
					transporterToken ? (
						<Navigate to='/transporter/home' replace />
					) : (
						<Navigate to='/transporter/login' replace />
					)
				}
			/>

			<Route
				exact
				path='/profile'
				element={
					<PrivateRoute>
						<Profile />
					</PrivateRoute>
				}
			/>
			<Route
				exact
				path='/change-password'
				element={
					<PrivateRoute>
						<ChangePassword />
					</PrivateRoute>
				}
			/>
			<Route path='*' element={<PAGE_404 />} />
		</Routes>
	);
};

export default ContentRoutes;
