import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../icon/Icon';
import Popovers from '../bootstrap/Popovers';

export const TimelineItem = forwardRef(
	(
		{
			className,
			labelClassName,
			color,
			label,
			subLabel,
			children,
			labelComponent,
			subLabelComponent,
			...props
		},
		ref,
	) => {
		return (
			// eslint-disable-next-line react/jsx-props-no-spreading
			<div ref={ref} className={classNames('timeline-item', className)} {...props}>
				<div
					className={classNames(
						'timeline-label text-truncate d-inline-block',
						labelClassName,
					)}>
					<Popovers desc={subLabel ? label.concat(' ', subLabel) : label} trigger='hover'>
						<>
							<span>{labelComponent ?? label}</span>
							<br />
							{subLabelComponent ?? subLabel ? (
								<span className='fw-light text-muted'>{subLabel}</span>
							) : undefined}
						</>
					</Popovers>
				</div>
				<div className='timeline-badge'>
					<Icon icon='Circle' color={color} size='lg' />
				</div>
				<div className='timeline-content ps-3'>{children}</div>
			</div>
		);
	},
);
TimelineItem.propTypes = {
	children: PropTypes.node.isRequired,
	labelComponent: PropTypes.node,
	subLabelComponent: PropTypes.node,
	className: PropTypes.string,
	labelClassName: PropTypes.string,
	color: PropTypes.oneOf([
		null,
		'primary',
		'secondary',
		'success',
		'info',
		'warning',
		'danger',
		'light',
		'dark',
	]),
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
	subLabel: PropTypes.string,
};
TimelineItem.defaultProps = {
	labelComponent: null,
	subLabelComponent: null,
	className: '',
	labelClassName: '',
	color: 'primary',
	subLabel: '',
};

const Timeline = forwardRef(({ className, children, widthLabel, ...props }, ref) => {
	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<div
			ref={ref}
			style={{ ...(widthLabel && { '--width-label': widthLabel }) }}
			className={classNames('timeline', className)}
			{...props}>
			{children}
		</div>
	);
});
Timeline.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	widthLabel: PropTypes.string,
};
Timeline.defaultProps = {
	className: null,
	widthLabel: null,
};

export default Timeline;
