import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Swal from 'sweetalert2';
import { useFormik } from 'formik';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import Card, { CardBody } from '../../components/bootstrap/Card';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import Input from '../../components/bootstrap/forms/Input';
import Button from '../../components/bootstrap/Button';
import LogoLNK from '../../components/LogoLNK';
import useDarkMode from '../../hooks/useDarkMode';
import Label from '../../components/bootstrap/forms/Label';
import Checks, { ChecksGroup } from '../../components/bootstrap/forms/Checks';
import InputGroup from '../../components/bootstrap/forms/InputGroup';
import RegistrasiVendorModule from '../../modules/sodiq/RegistrasiVendorModule';
import Modal, { ModalBody } from '../../components/bootstrap/Modal';
import Icon from '../../components/icon/Icon';

const errorStyle = {
	width: '100%',
	marginTop: '0.25rem',
	fontSize: '0.875em',
	color: '#f35421',
};

const ModalLoading = (dt) => {
	const { loading, setLoading } = dt;
	return (
		<Modal isOpen={loading} size='sm' isCentered setIsOpen={setLoading} isStaticBackdrop>
			<ModalBody
				style={{ backgroundColor: '#6c5dd3', color: 'white' }}
				className='text-center'>
				<button className='btn btn-primary' type='button' disabled>
					<span
						className='spinner-grow spinner-grow-sm'
						role='status'
						aria-hidden='true'
					/>
					&nbsp;
					<span
						className='spinner-grow spinner-grow-sm'
						role='status'
						aria-hidden='true'
					/>
					&nbsp;
					<span
						className='spinner-grow spinner-grow-sm'
						role='status'
						aria-hidden='true'
					/>
					&nbsp;
					<span className='sr-only'>Loading...</span>
				</button>
			</ModalBody>
		</Modal>
	);
};

const validateEmail = (email) => {
	return String(email)
		.toLowerCase()
		.match(
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
		);
};

const validate = (values) => {
	const errors = {};
	if (!values.name || values.name === '') {
		const key = 'name';
		errors[key] = 'Required';
	}
	if (!values.email || values.email === '') {
		const key = 'email';
		errors[key] = 'Required';
	}
	if (values.email && !validateEmail(values.email)) {
		const key = 'email';
		errors[key] = 'Email not valid';
	}
	if (!values.street || values.street === '') {
		const key = 'street';
		errors[key] = 'Required';
	}
	if (!values.username || values.username === '') {
		const key = 'username';
		errors[key] = 'Required';
	}
	if (!values.password || values.password === '') {
		const key = 'password';
		errors[key] = 'Required';
	}
	if (!values.confirm_password || values.confirm_password === '') {
		const key = 'confirm_password';
		errors[key] = 'Required';
	}

	if (values.password && values.confirm_password) {
		if (values.password !== values.confirm_password) {
			const key = 'confirm_password';
			errors[key] = 'Confirm Password not match';
		}
	}

	if (!values.no_npwp || values.no_npwp === '') {
		const key = 'no_npwp';
		errors[key] = 'Required';
	}
	if (!values.file_npwp || values.file_npwp === '') {
		const key = 'file_npwp';
		errors[key] = 'Required';
	}

	if (values.vendor_type === 'legal_subject') {
		if (!values.no_tdp || values.no_tdp === '') {
			const key = 'no_tdp';
			errors[key] = 'Required';
		}
		if (!values.file_tdp || values.file_tdp === '') {
			const key = 'file_tdp';
			errors[key] = 'Required';
		}
		if (!values.no_siup || values.no_siup === '') {
			const key = 'no_siup';
			errors[key] = 'Required';
		}
		if (!values.file_siup || values.file_siup === '') {
			const key = 'file_siup';
			errors[key] = 'Required';
		}
		if (!values.exp_date_tdp || values.exp_date_tdp === '') {
			const key = 'exp_date_tdp';
			errors[key] = 'Required';
		}
		if (!values.exp_date_siup || values.exp_date_siup === '') {
			const key = 'exp_date_siup';
			errors[key] = 'Required';
		}
	}
	return errors;
};

const Main = () => {
	const [loading, setLoading] = useState(false);
	const [showPass, setShowPass] = useState(false);
	const [showPassConfirm, setShowPassConfirm] = useState(false);
	const { darkModeStatus } = useDarkMode();
	const navigate = useNavigate();

	const handleRegister = (values) => {
		Swal.fire({
			title: 'Are you sure?',
			text: 'Please check your entries !',
			icon: 'question',
			showCancelButton: true,
			confirmButtonText: 'Yes',
			didRender: (popup) => {
				popup.querySelector('.swal2-actions .swal2-confirm').id = 'buttonYes';
				popup.querySelector('.swal2-actions .swal2-cancel').id = 'buttonCancel';
			},
		}).then((result) => {
			if (result.isConfirmed) {
				setLoading(true);
				const formData = new FormData();
				formData.append('files_upload', values.file_npwp_object);
				if (values.vendor_type === 'legal_subject') {
					formData.append('files_upload', values.file_tdp_object);
					formData.append('files_upload', values.file_siup_object);
				}
				formData.append('data', JSON.stringify(values));
				RegistrasiVendorModule.register(formData)
					.then(() => {
						Swal.fire({
							icon: 'success',
							title: 'Success',
							html: `<p>We have send email to <b>${values.email}</b>, please check your email to continue</p>`,
							didRender: (popup) => {
								popup.id = 'notificationSuccess';
							},
						});
						formik.resetForm();
						navigate('/login');
					})
					.catch((err) => {
						Swal.fire('Warning', err, 'error');
					})
					.finally(() => {
						setLoading(false);
					});
			} else if (result.dismiss === Swal.DismissReason.cancel) {
				Swal.fire('Cancelled', 'Your data is safe :)', 'error');
			}
		});
	};

	const formik = useFormik({
		initialValues: {
			name: '',
			email: '',
			street: '',
			username: '',
			password: '',
			confirm_password: '',
			vendor_type: 'individual',
			no_npwp: '',
			file_npwp: '',
			no_tdp: '',
			file_tdp: '',
			exp_date_tdp: '',
			no_siup: '',
			file_siup: '',
			exp_date_siup: '',
		},
		validate,
		onSubmit: (values) => {
			// eslint-disable-next-line no-alert
			handleRegister(values);
		},
	});

	useEffect(() => {
		formik.resetForm();
		setLoading(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onChangeType = (event, formiks) => {
		formiks.setFieldValue('vendor_type', event.target.value);
		formiks.setFieldValue('no_tdp', '');
		formiks.setFieldValue('file_tdp', '');
		formiks.setFieldValue('file_tdp_object', '');
		formiks.setFieldValue('no_siup', '');
		formiks.setFieldValue('file_siup', '');
		formiks.setFieldValue('file_tdp_object', '');
	};

	const onChangeFile = (e, formiks, name) => {
		if (e.target.files.length > 0) {
			const fileObject = e.target.files[0];
			const ext = fileObject.name.split('.').pop();
			let check = false;
			if (!['jpeg', 'pdf'].includes(ext)) {
				Swal.fire('Warning', 'Please upload extension JPEG | PDF!', 'error');
				formiks.setFieldValue(name, '');
				check = true;
			}
			if (fileObject.size > 2101546) {
				Swal.fire('Warning', 'Please upload max size 2MB!', 'error');
				formiks.setFieldValue(name, '');
				check = true;
			}
			if (!check) {
				formiks.setFieldValue(`${name}_object`, fileObject);
				formiks.handleChange(e);
			}
		}
	};

	return (
		<PageWrapper title='Register' className={classNames('bg-info')}>
			<ModalLoading loading={loading} setLoading={setLoading} />
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-8 shadow-3d-container p-5'>
						<Card
							className='shadow-3d-dark'
							data-tour='register-page'
							stretch
							tag='form'
							noValidate
							onSubmit={formik.handleSubmit}>
							<CardBody>
								<div className='text-center my-2'>
									<Link
										to='/'
										className={classNames(
											'text-decoration-none  fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}>
										<LogoLNK width={150} />
									</Link>
								</div>
								<div className='text-center h3 fw-bold mt-2'>Register Vendor</div>
								<div className='row mt-4'>
									<div className='col-12'>
										<div className='h5'>Vendor Information</div>
									</div>
								</div>
								<div className='row mt-3'>
									<div className='col-6'>
										<FormGroup id='inputName' label='Name'>
											<Input
												autoComplete='off'
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												name='name'
												isValid={formik.isValid}
												invalidFeedback={formik.errors.name}
												value={formik.values.name}
												isTouched={formik.touched.name}
												readOnly={loading}
											/>
										</FormGroup>
									</div>
									<div className='col-6'>
										<FormGroup id='inputUsername' label='Username'>
											<Input
												autoComplete='off'
												type='text'
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												name='username'
												isValid={formik.isValid}
												invalidFeedback={formik.errors.username}
												value={formik.values.username}
												isTouched={formik.touched.username}
												readOnly={loading}
											/>
										</FormGroup>
									</div>
								</div>
								<div className='row mt-3'>
									<div className='col-6'>
										<FormGroup id='inputEmail' label='Email'>
											<Input
												autoComplete='off'
												type='text'
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												name='email'
												isValid={formik.isValid}
												invalidFeedback={formik.errors.email}
												value={formik.values.email}
												isTouched={formik.touched.email}
												readOnly={loading}
											/>
										</FormGroup>
									</div>
									<div className='col-6'>
										<FormGroup id='inputPassword' label='Password'>
											<InputGroup>
												<Input
													autoComplete='off'
													type={showPass ? 'text' : 'password'}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													name='password'
													isValid={formik.isValid}
													invalidFeedback={formik.errors.password}
													value={formik.values.password}
													isTouched={formik.touched.password}
													readOnly={loading}
												/>
												<Button
													icon={showPass ? 'eye_slash' : 'eye'}
													color={showPass ? 'primary' : 'light'}
													type='button'
													onClick={() => setShowPass(!showPass)}
												/>
											</InputGroup>
										</FormGroup>
									</div>
								</div>
								<div className='row mt-3'>
									<div className='col-6'>
										<FormGroup id='inputAddress' label='Address'>
											<Input
												autoComplete='off'
												type='text'
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												name='street'
												isValid={formik.isValid}
												invalidFeedback={formik.errors.street}
												value={formik.values.street}
												isTouched={formik.touched.street}
												readOnly={loading}
											/>
										</FormGroup>
									</div>
									<div className='col-6'>
										<FormGroup
											id='inputConfirmPassword'
											label='Confirmation Password'>
											<InputGroup>
												<Input
													autoComplete='off'
													type={showPassConfirm ? 'text' : 'password'}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													name='confirm_password'
													isValid={formik.isValid}
													invalidFeedback={formik.errors.confirm_password}
													value={formik.values.confirm_password}
													isTouched={formik.touched.confirm_password}
													readOnly={loading}
												/>
												<Button
													icon={showPassConfirm ? 'eye_slash' : 'eye'}
													color={showPassConfirm ? 'primary' : 'light'}
													type='button'
													onClick={() =>
														setShowPassConfirm(!showPassConfirm)
													}
												/>
											</InputGroup>
										</FormGroup>
									</div>
								</div>
								<div className='row mt-4'>
									<div className='col-12'>
										<h5>
											Document Attactment{' '}
											<span
												style={{
													// marginTop: '-8px',
													fontSize: '12px',
													color: 'grey',
												}}>
												(Max size 2MB with JPEG, PDF extension)
											</span>
										</h5>
									</div>
								</div>
								<div className='row mt-2'>
									<div className='col-12'>
										<Label>Type</Label>
										<ChecksGroup isInline>
											<Checks
												type='radio'
												id='checkIndividual'
												label='Individual'
												name='vendor_type'
												value='individual'
												onChange={(e) => onChangeType(e, formik)}
												checked={formik.values.vendor_type}
											/>
											<Checks
												type='radio'
												id='checkLegalSubject'
												label='Legal Subject'
												name='vendor_type'
												value='legal_subject'
												onChange={(e) => onChangeType(e, formik)}
												checked={formik.values.vendor_type}
											/>
										</ChecksGroup>
									</div>
								</div>
								<div className='row mt-3'>
									<div className='col-6'>
										<FormGroup id='inputNo.NPWP' label='No. NPWP'>
											<Input
												autoComplete='off'
												type='text'
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												name='no_npwp'
												isValid={formik.isValid}
												invalidFeedback={formik.errors.no_npwp}
												value={formik.values.no_npwp}
												isTouched={formik.touched.no_npwp}
												readOnly={loading}
											/>
										</FormGroup>
									</div>
									<div className='col-6'>
										<FormGroup id='inputUploadNpwp' label='Upload NPWP'>
											<Input
												autoComplete='off'
												accept='.JPEG, .pdf'
												type='file'
												onChange={(e) => {
													onChangeFile(e, formik, 'file_npwp');
												}}
												onBlur={formik.handleBlur}
												name='file_npwp'
												isValid={formik.isValid}
												invalidFeedback={formik.errors.file_npwp}
												value={formik.values.file_npwp}
												isTouched={formik.touched.file_npwp}
												readOnly={loading}
											/>
										</FormGroup>
									</div>
								</div>
								{formik.values.vendor_type === 'legal_subject' && (
									<>
										<div className='row mt-3'>
											<div className='col-4'>
												<FormGroup id='inputNo.TDP' label='No. TDP'>
													<Input
														autoComplete='off'
														type='text'
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														name='no_tdp'
														isValid={formik.isValid}
														invalidFeedback={formik.errors.no_tdp}
														value={formik.values.no_tdp}
														isTouched={formik.touched.no_tdp}
														readOnly={loading}
													/>
												</FormGroup>
											</div>
											<div className='col-5'>
												<FormGroup id='inputUploadTDP' label='Upload TDP'>
													<Input
														autoComplete='off'
														accept='.JPEG, .pdf'
														type='file'
														onChange={(e) => {
															onChangeFile(e, formik, 'file_tdp');
														}}
														onBlur={formik.handleBlur}
														name='file_tdp'
														isValid={formik.isValid}
														invalidFeedback={formik.errors.file_tdp}
														value={formik.values.file_tdp}
														isTouched={formik.touched.file_tdp}
														readOnly={loading}
													/>
												</FormGroup>
											</div>
											<div className='col-3'>
												<Label>Exp. Date</Label>
												<InputGroup>
													<DatePicker
														id='datepickerExp.DateTDP'
														autoComplete='off'
														onBlur={formik.handleBlur}
														name='exp_date_tdp'
														isValid={formik.isValid}
														invalidFeedback={formik.errors.exp_date_tdp}
														value={formik.values.exp_date_tdp}
														isTouched={formik.touched.exp_date_tdp}
														selected={formik.values.exp_date_tdp}
														placeholderText='dd/mm/yyyy'
														className={
															formik.errors.exp_date_tdp
																? 'form-control is-invalid'
																: 'form-control is-valid'
														}
														dateFormat='dd/MM/yyyy'
														onChange={(e) => {
															formik.setFieldValue('exp_date_tdp', e);
														}}
													/>
													<Icon
														className='float-end'
														icon='CalendarToday'
														color='dark'
														style={{
															marginTop: '-24px',
															zIndex: '0',
															marginLeft: '140px',
														}}
													/>
												</InputGroup>
												{formik.errors.exp_date_tdp && (
													<div style={errorStyle}>
														{formik.errors.exp_date_tdp}
													</div>
												)}
											</div>
										</div>
										<div className='row mt-3'>
											<div className='col-4'>
												<FormGroup id='inputNo.SIUP' label='No. SIUP'>
													<Input
														autoComplete='off'
														type='text'
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														name='no_siup'
														isValid={formik.isValid}
														invalidFeedback={formik.errors.no_siup}
														value={formik.values.no_siup}
														isTouched={formik.touched.no_siup}
														readOnly={loading}
													/>
												</FormGroup>
											</div>
											<div className='col-5'>
												<FormGroup id='inputUploadSIUP' label='Upload SIUP'>
													<Input
														autoComplete='off'
														accept='.JPEG, .pdf'
														type='file'
														onChange={(e) => {
															onChangeFile(e, formik, 'file_siup');
														}}
														onBlur={formik.handleBlur}
														name='file_siup'
														isValid={formik.isValid}
														invalidFeedback={formik.errors.file_siup}
														value={formik.values.file_siup}
														isTouched={formik.touched.file_siup}
														readOnly={loading}
													/>
												</FormGroup>
											</div>
											<div className='col-3'>
												<Label>Exp. Date</Label>
												<InputGroup>
													<DatePicker
														id='datepickerExp.DateSIUP'
														autoComplete='off'
														onBlur={formik.handleBlur}
														name='exp_date_siup'
														isValid={formik.isValid}
														invalidFeedback={
															formik.errors.exp_date_siup
														}
														value={formik.values.exp_date_siup}
														isTouched={formik.touched.exp_date_siup}
														selected={formik.values.exp_date_siup}
														placeholderText='dd/mm/yyyy'
														className={
															formik.errors.exp_date_siup
																? 'form-control is-invalid'
																: 'form-control is-valid'
														}
														dateFormat='dd/MM/yyyy'
														onChange={(e) => {
															formik.setFieldValue(
																'exp_date_siup',
																e,
															);
														}}
													/>
													<Icon
														className='float-end'
														icon='CalendarToday'
														color='dark'
														style={{
															marginTop: '-24px',
															zIndex: '0',
															marginLeft: '140px',
														}}
													/>
												</InputGroup>
												{formik.errors.exp_date_siup && (
													<div style={errorStyle}>
														{formik.errors.exp_date_siup}
													</div>
												)}
											</div>
										</div>
									</>
								)}
								<div className='row mt-3'>
									<div className='col-12'>
										<Button
											id='buttonRegister'
											type='submit'
											color='primary'
											className='w-100 py-3'>
											Register
										</Button>
									</div>
								</div>
								<div className='row mt-2'>
									<div className='col-12'>
										<Label>Have an account? </Label>{' '}
										<Label
											style={{ color: '#4d69fa', cursor: 'pointer' }}
											onClick={() => {
												navigate('/login');
											}}>
											{' '}
											Login
										</Label>
									</div>
								</div>
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default Main;
