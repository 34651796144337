import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import IFrame from 'react-iframe';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Swal from 'sweetalert2';
import moment from 'moment';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import PageLayoutHeader from '../../pages/common/Headers/PageLayoutHeader';
import Card, {
	CardBody,
	CardFooter,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../../components/bootstrap/Card';
import Input from '../../components/bootstrap/forms/Input';
import Button from '../../components/bootstrap/Button';
import showNotification from '../../components/extras/showNotification';
import ProfileModule from '../../modules/sodiq/ProfileModule';
import { getRequester } from '../../helpers/helpers';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import Select from '../../components/bootstrap/forms/Select';
import Label from '../../components/bootstrap/forms/Label';
import Icon from '../../components/icon/Icon';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../components/bootstrap/Modal';
import Spinner from '../../components/bootstrap/Spinner';
import InputGroup from '../../components/bootstrap/forms/InputGroup';

const ModalLoading = (dt) => {
	const { loading, setLoading } = dt;
	return (
		<Modal isOpen={loading} size='sm' isCentered setIsOpen={setLoading} isStaticBackdrop>
			<ModalBody
				style={{ backgroundColor: '#6c5dd3', color: 'white' }}
				className='text-center'>
				<button className='btn btn-primary' type='button' disabled>
					<span
						className='spinner-grow spinner-grow-sm'
						role='status'
						aria-hidden='true'
					/>
					&nbsp;
					<span
						className='spinner-grow spinner-grow-sm'
						role='status'
						aria-hidden='true'
					/>
					&nbsp;
					<span
						className='spinner-grow spinner-grow-sm'
						role='status'
						aria-hidden='true'
					/>
					&nbsp;
					<span className='sr-only'>Loading...</span>
				</button>
			</ModalBody>
		</Modal>
	);
};

const validateEmail = (email) => {
	return String(email)
		.toLowerCase()
		.match(
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
		);
};

const ModalPreview = (dt) => {
	const { loading, title, isOpen, setIsOpen, size, contentDocument, isImg } = dt;
	return (
		<Modal
			isOpen={isOpen}
			setIsOpen={setIsOpen}
			isStaticBackdrop
			isCentered
			size={
				// eslint-disable-next-line no-nested-ternary
				isImg
					? 'lg'
					: // eslint-disable-next-line no-nested-ternary
					contentDocument
					? size
					: 'lg'
			}>
			<ModalHeader setIsOpen={setIsOpen} className='p-4'>
				<ModalTitle id='modal-preview'>{title}</ModalTitle>
			</ModalHeader>
			<ModalBody>
				<Card shadow='none'>
					<CardBody>
						{loading && (
							<div className='text-center'>
								<Spinner isGrow color='success' size='lg' />
								<Spinner isGrow color='success' size='lg' />
								<Spinner isGrow color='success' size='lg' />
								<Spinner isGrow color='success' size='lg' />
								<Spinner isGrow color='success' size='lg' />
							</div>
						)}
						{!loading && !contentDocument && (
							<div className='text-center'>
								<h3>Document Not Found</h3>
							</div>
						)}
						{!loading && isImg && contentDocument && (
							<div className='text-center'>
								<img
									alt=''
									width='100%'
									src={contentDocument}
									className='rounded'
								/>
							</div>
						)}
						{!loading && !isImg && contentDocument && (
							<IFrame
								src={contentDocument}
								width='1060px'
								height={!isImg ? `${window.innerHeight - 160}px` : 'auto'}
								id='myId'
								className='myClassname'
								display='initial'
								position='relative'
							/>
						)}
					</CardBody>
				</Card>
			</ModalBody>
		</Modal>
	);
};

const handleSubmit = (values, fecthData, setLoading, formik) => {
	if ([null, ''].includes(formik.values.file_npwp)) {
		delete formik.values?.file_npwp;
	}
	if ([null, ''].includes(formik.values.file_tdp)) {
		delete formik.values?.file_tdp;
	}
	if ([null, ''].includes(formik.values.file_siup)) {
		delete formik.values?.file_siup;
	}
	if (JSON.stringify(formik.initialValues) === JSON.stringify(formik.values)) {
		Swal.fire('Cancelled', `Your data has not changed!`, 'error');
		return;
	}

	let checkEmail = false;
	let falseEmail = '';
	const temp_email = [];

	if (values.email_list) {
		values.email_list.map((item) => {
			if (!validateEmail(item) && item) {
				checkEmail = true;
				if (falseEmail === '') {
					falseEmail = `${item}`;
				} else {
					falseEmail = `${falseEmail}, ${item}`;
				}
			}
			if (validateEmail(item) && item) {
				temp_email.push(item);
			}
			return item;
		});
	}

	if (checkEmail) {
		Swal.fire({
			title: 'Warning',
			html: `Email <b>${falseEmail}</b> Not valid`,
			icon: 'error',
		});
		return;
	}

	Swal.fire({
		title: 'Are you sure?',
		text: 'Please check your entries !',
		icon: 'info',
		showCancelButton: true,
		confirmButtonText: 'Yes',
	}).then((result) => {
		if (result.value) {
			setLoading(true);
			values.email = temp_email;
			if (values.exp_date_tdp) {
				values.exp_date_tdp = moment(values.exp_date_tdp).format('YYYY-MM-DD');
			}
			if (values.exp_date_siup) {
				values.exp_date_siup = moment(values.exp_date_siup).format('YYYY-MM-DD');
			}
			const formData = new FormData();
			const resetFile = [];
			if (values.file_npwp_object) {
				formData.append('npwp_files', values.file_npwp_object);
				resetFile.push('file_npwp');
			}
			if (values.file_tdp_object) {
				formData.append('tdp_files', values.file_tdp_object);
				resetFile.push('file_tdp');
			}
			if (values.file_siup_object) {
				formData.append('siup_files', values.file_siup_object);
				resetFile.push('file_siup');
			}
			formData.append('data', JSON.stringify(values));

			ProfileModule.update(formData)
				.then(() => {
					Swal.fire('Success', 'Data has been updated Successfully', 'success');
					formik.resetForm();
					resetFile.map((id) => {
						document.getElementById(id).value = null;
						return id;
					});
					fecthData();
				})
				.catch((err) => {
					Swal.fire('Warning', err, 'error');
				})
				.finally(() => {
					setLoading(false);
				});
		} else if (result.dismiss === Swal.DismissReason.cancel) {
			Swal.fire('Cancelled', 'Your data is safe :)', 'error');
		}
	});
};

const GeneralData = (dt) => {
	const { formik, emailList, setEmailList } = dt;
	const handleAdd = () => {
		setEmailList([...emailList, '']);
	};
	const handleDelete = (index) => {
		const temp = [...emailList];
		temp.splice(index, 1);
		formik.setFieldValue('email_list', temp);
		setEmailList(temp);
	};
	const onChangeValue = (value, index) => {
		const temp = [...emailList];
		let check = false;
		let msg = '';
		temp.map((item) => {
			if (item === value) {
				check = true;
				msg = item;
			}
			return item;
		});
		if (check) {
			temp[index] = '';
			Swal.fire('Warning', `${msg} already exist`, 'error');
		} else {
			temp[index] = value;
		}
		formik.setFieldValue('email_list', temp);
		setEmailList(temp);
	};
	return (
		<>
			<div className='row'>
				<div className='col-12'>
					<h5>General Data</h5>
				</div>
			</div>
			<div className='row mt-2'>
				<div className='col-6'>
					<FormGroup isFloating id='inputTitle' label='Title'>
						<Input
							autoComplete='off'
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							name='title'
							isValid={formik.isValid}
							invalidFeedback={formik.errors.title}
							value={formik.values.title}
							isTouched={formik.touched.title}
						/>
					</FormGroup>
				</div>
				<div className='col-6'>
					<FormGroup isFloating id='inputName' label='Name'>
						<Input
							autoComplete='off'
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							name='name'
							isValid={formik.isValid}
							invalidFeedback={formik.errors.name}
							value={formik.values.name}
							isTouched={formik.touched.name}
						/>
					</FormGroup>
				</div>
			</div>
			<div className='row mt-3'>
				<div className='col-6'>
					<FormGroup isFloating id='inputAddress' label='Address'>
						<Input
							autoComplete='off'
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							name='street'
							isValid={formik.isValid}
							invalidFeedback={formik.errors.street}
							value={formik.values.street}
							isTouched={formik.touched.street}
						/>
					</FormGroup>
				</div>
				<div className='col-6'>
					<FormGroup isFloating id='inputCity' label='City'>
						<Input
							autoComplete='off'
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							name='city'
							isValid={formik.isValid}
							invalidFeedback={formik.errors.city}
							value={formik.values.city}
							isTouched={formik.touched.city}
						/>
					</FormGroup>
				</div>
			</div>
			<div className='row mt-3'>
				<div className='col-12'>
					<FormGroup
						isFloating
						id='inputVATRegistrationNumber'
						label='VAT Registration Number'>
						<Input
							autoComplete='off'
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							name='vat'
							isValid={formik.isValid}
							invalidFeedback={formik.errors.vat}
							value={formik.values.vat}
							isTouched={formik.touched.vat}
						/>
					</FormGroup>
				</div>
			</div>
			<div className='row mt-3 g-3'>
				<div className='col-12'>
					<Card>
						<CardBody>
							<h5>Email</h5>
							{emailList.map((item, i) => (
								<div className='row mt-3'>
									<div className='col-11'>
										<FormGroup
											isFloating
											id='inputEmail'
											autoComplete='off'
											label='Email'>
											<Input
												value={item}
												onChange={(e) => onChangeValue(e.target.value, i)}
											/>
										</FormGroup>
									</div>
									{i > 0 && (
										<div className='col-1'>
											<Button
												onClick={() => handleDelete(i)}
												icon='Clear'
												isOutline
												color='danger'
											/>
										</div>
									)}
								</div>
							))}
							<Button
								onClick={() => handleAdd()}
								icon='Add'
								isOutline
								color='primary'
								className='mt-3'>
								Add More
							</Button>
						</CardBody>
					</Card>
				</div>
			</div>
		</>
	);
};

const BankVendor = (dt) => {
	const { formik, bankList } = dt;
	return (
		<>
			<div className='row mt-4'>
				<div className='col-12'>
					<h5>Bank Vendor</h5>
				</div>
			</div>
			<div className='row mt-2'>
				<div className='col-6'>
					<FormGroup isFloating id='selectBankName' label='Bank Name'>
						<Select
							name='bank_name'
							autoComplete='off'
							ariaLabel='State'
							placeholder='Choose...'
							list={bankList}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.bank_name}
							isValid={formik.isValid}
							isTouched={formik.touched.bank_name}
							invalidFeedback={formik.errors.bank_name}
						/>
					</FormGroup>
				</div>
				<div className='col-6'>
					<FormGroup isFloating id='inputBranchName' label='Branch Name'>
						<Input
							autoComplete='off'
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							name='bank_branch'
							isValid={formik.isValid}
							invalidFeedback={formik.errors.bank_branch}
							value={formik.values.bank_branch}
							isTouched={formik.touched.bank_branch}
						/>
					</FormGroup>
				</div>
			</div>
			<div className='row mt-3'>
				<div className='col-6'>
					<FormGroup isFloating id='inputBankAccountNumber' label='Bank Account Number'>
						<Input
							autoComplete='off'
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							name='account_number'
							isValid={formik.isValid}
							invalidFeedback={formik.errors.account_number}
							value={formik.values.account_number}
							isTouched={formik.touched.account_number}
						/>
					</FormGroup>
				</div>
				<div className='col-6'>
					<FormGroup isFloating id='inputAccountHolder' label='Account Holder'>
						<Input
							autoComplete='off'
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							name='account_holder'
							isValid={formik.isValid}
							invalidFeedback={formik.errors.account_holder}
							value={formik.values.account_holder}
							isTouched={formik.touched.account_holder}
						/>
					</FormGroup>
				</div>
			</div>
		</>
	);
};

const CompanyCodeData = (dt) => {
	const { formik, methodList, termsList } = dt;
	return (
		<>
			<div className='row mt-4'>
				<div className='col-12'>
					<h5>Company Code Data</h5>
				</div>
			</div>
			<div className='row mt-2'>
				<div className='col-6'>
					<FormGroup isFloating id='selectTermsofPayment' label='Terms of Payment'>
						<Select
							name='term_of_payment'
							autoComplete='off'
							ariaLabel='State'
							placeholder='Choose...'
							list={termsList}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.term_of_payment}
							isValid={formik.isValid}
							isTouched={formik.touched.term_of_payment}
							invalidFeedback={formik.errors.term_of_payment}
						/>
					</FormGroup>
				</div>
				<div className='col-6'>
					<FormGroup isFloating id='inputPaymentMethod' label='Payment Method'>
						<Select
							name='payment_method'
							autoComplete='off'
							ariaLabel='State'
							placeholder='Choose...'
							list={methodList}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.payment_method}
							isValid={formik.isValid}
							isTouched={formik.touched.payment_method}
							invalidFeedback={formik.errors.payment_method}
						/>
					</FormGroup>
				</div>
			</div>
		</>
	);
};

const PurchasingOrganizationData = (dt) => {
	const { formik } = dt;
	return (
		<>
			<div className='row mt-4'>
				<div className='col-12'>
					<h5>Purchasing Organization Data</h5>
				</div>
			</div>
			<div className='row mt-2'>
				<div className='col-6'>
					<FormGroup isFloating id='inputContactPerson' label='Contact Person'>
						<Input
							autoComplete='off'
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							name='contact_person_name'
							isValid={formik.isValid}
							invalidFeedback={formik.errors.contact_person_name}
							value={formik.values.contact_person_name}
							isTouched={formik.touched.contact_person_name}
						/>
					</FormGroup>
				</div>
				<div className='col-6'>
					<FormGroup
						isFloating
						id={`inputContactPerson'sPhone No.`}
						label={`Contact Person's Phone No.`}>
						<Input
							autoComplete='off'
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							name='contact_person_phone'
							isValid={formik.isValid}
							invalidFeedback={formik.errors.contact_person_phone}
							value={formik.values.contact_person_phone}
							isTouched={formik.touched.contact_person_phone}
						/>
					</FormGroup>
				</div>
			</div>
		</>
	);
};

const AttactmentData = (dt) => {
	const { formik } = dt;
	const [stringBase64, setStringBase64] = useState(null);
	const [isOpenPreview, setIsOpenPreview] = useState(false);
	const [isImg, setIsImg] = useState(false);
	const [loading, setLoading] = useState(false);
	const [title, setTitle] = useState('');

	const onChangeFile = (e, formiks, name) => {
		if (e.target.files.length > 0) {
			const fileObject = e.target.files[0];
			const ext = fileObject.name.split('.').pop();
			let check = false;
			if (fileObject.size > 2101546) {
				Swal.fire('Warning', 'Please upload max size 2MB!', 'error');
				check = true;
			}
			if (!['jpeg', 'pdf'].includes(ext)) {
				Swal.fire('Warning', 'Please upload extension JPEG | PDF!', 'error');
				check = true;
			}

			if (!check) {
				formiks.setFieldValue(`${name}_object`, fileObject);
			} else {
				e.target.value = '';
			}
			formiks.handleChange(e);
		}
	};
	const handlePreview = (onedrive_id, tittle) => {
		setStringBase64(null);
		setLoading(true);
		setIsOpenPreview(true);
		setTitle(tittle);
		const params = {
			onedrive_id,
		};
		ProfileModule.preview(new URLSearchParams(params))
			.then((res) => {
				setIsImg(true);
				if (res.mimetype.includes('application')) {
					setIsImg(false);
				}
				const base64 = `data:${res.mimetype};base64,${res.file}`;
				setStringBase64(base64);
			})
			.catch(() => {
				setStringBase64(null);
			})
			.finally(() => {
				setLoading(false);
			});
	};
	return (
		<>
			<ModalPreview
				isOpen={isOpenPreview}
				setIsOpen={setIsOpenPreview}
				contentDocument={stringBase64}
				size='xl'
				title={title}
				isImg={isImg}
				loading={loading}
			/>
			<div className='row'>
				<div className='col-6'>
					<FormGroup id='inputNo.NPWP' label='No. NPWP'>
						<Input
							autoComplete='off'
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							name='no_npwp'
							isValid={formik.isValid}
							invalidFeedback={formik.errors.no_npwp}
							value={formik.values.no_npwp}
							isTouched={formik.touched.no_npwp}
						/>
					</FormGroup>
				</div>
				<div className='col-6'>
					<Label>
						NPWP{' '}
						{formik.values.onedrive_id_npwp && (
							<Icon
								style={{ cursor: 'pointer' }}
								icon='eye'
								size='lg'
								color='info'
								role='presentation'
								onClick={() =>
									handlePreview(formik.values.onedrive_id_npwp, 'NPWP')
								}
							/>
						)}
					</Label>
					<Input
						id='file_npwp'
						accept='.JPEG, .pdf'
						type='file'
						onChange={(e) => {
							onChangeFile(e, formik, 'file_npwp');
						}}
						onBlur={formik.handleBlur}
						name='file_npwp'
						isValid={formik.isValid}
						invalidFeedback={formik.errors.file_npwp}
						value={formik.values.file_npwp}
						isTouched={formik.touched.file_npwp}
					/>
					<span
						style={{
							fontSize: '12px',
							color: 'grey',
						}}>
						Max size 2MB with JPEG, PDF extension
					</span>
				</div>
			</div>
			<div className='row mt-4'>
				<div className='col-4'>
					<FormGroup id='inputNo.TDP' label='No. TDP'>
						<Input
							autoComplete='off'
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							name='no_tdp'
							isValid={formik.isValid}
							invalidFeedback={formik.errors.no_tdp}
							value={formik.values.no_tdp}
							isTouched={formik.touched.no_tdp}
						/>
					</FormGroup>
				</div>
				<div className='col-5'>
					<Label>
						TDP{' '}
						{formik.values.onedrive_id_tdp && (
							<Icon
								style={{ cursor: 'pointer' }}
								icon='eye'
								size='lg'
								color='info'
								role='presentation'
								onClick={() => handlePreview(formik.values.onedrive_id_tdp, 'TDP')}
							/>
						)}
					</Label>
					<Input
						id='file_tdp'
						accept='.JPEG, .pdf'
						type='file'
						onChange={(e) => {
							onChangeFile(e, formik, 'file_tdp');
						}}
						onBlur={formik.handleBlur}
						name='file_tdp'
						isValid={formik.isValid}
						invalidFeedback={formik.errors.file_tdp}
						value={formik.values.file_tdp}
						isTouched={formik.touched.file_tdp}
					/>
					<span
						style={{
							fontSize: '12px',
							color: 'grey',
						}}>
						Max size 2MB with JPEG, PDF extension
					</span>
				</div>
				<div className='col-3'>
					<Label>Exp. Date</Label>
					<InputGroup>
						<DatePicker
							id='dateDd/mm/yyyy'
							autoComplete='off'
							onBlur={formik.handleBlur}
							name='exp_date_tdp'
							isValid={formik.isValid}
							invalidFeedback={formik.errors.exp_date_tdp}
							value={formik.values.exp_date_tdp}
							isTouched={formik.touched.exp_date_tdp}
							selected={formik.values.exp_date_tdp}
							placeholderText='dd/mm/yyyy'
							className='form-control'
							dateFormat='dd/MM/yyyy'
							onChange={(e) => {
								formik.setFieldValue('exp_date_tdp', e);
							}}
						/>
						<Icon
							className='float-end'
							icon='CalendarToday'
							color='dark'
							style={{ marginTop: '-25px', zIndex: '0', marginLeft: '150px' }}
						/>
					</InputGroup>
				</div>
			</div>
			<div className='row mt-4'>
				<div className='col-4'>
					<FormGroup id='inputNo.SIUP' label='No. SIUP'>
						<Input
							autoComplete='off'
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							name='no_siup'
							isValid={formik.isValid}
							invalidFeedback={formik.errors.no_siup}
							value={formik.values.no_siup}
							isTouched={formik.touched.no_siup}
						/>
					</FormGroup>
				</div>
				<div className='col-5'>
					<Label>
						SIUP{' '}
						{formik.values.onedrive_id_siup && (
							<Icon
								style={{ cursor: 'pointer' }}
								icon='eye'
								size='lg'
								color='info'
								role='presentation'
								onClick={() =>
									handlePreview(formik.values.onedrive_id_siup, 'SIUP')
								}
							/>
						)}
					</Label>
					<Input
						id='file_siup'
						accept='.JPEG, .pdf'
						type='file'
						onChange={(e) => {
							onChangeFile(e, formik, 'file_siup');
						}}
						onBlur={formik.handleBlur}
						name='file_siup'
						isValid={formik.isValid}
						invalidFeedback={formik.errors.file_siup}
						value={formik.values.file_siup}
						isTouched={formik.touched.file_siup}
					/>
					<span
						style={{
							fontSize: '12px',
							color: 'grey',
						}}>
						Max size 2MB with JPEG, PDF extension
					</span>
				</div>
				<div className='col-3'>
					<Label>Exp. Date</Label>
					<InputGroup>
						<DatePicker
							id='dateDd/mm/yyyy'
							autoComplete='off'
							onBlur={formik.handleBlur}
							name='exp_date_siup'
							isValid={formik.isValid}
							invalidFeedback={formik.errors.exp_date_siup}
							value={formik.values.exp_date_siup}
							isTouched={formik.touched.exp_date_siup}
							selected={formik.values.exp_date_siup}
							placeholderText='dd/mm/yyyy'
							className='form-control'
							dateFormat='dd/MM/yyyy'
							onChange={(e) => {
								formik.setFieldValue('exp_date_siup', e);
							}}
						/>
						<Icon
							className='float-end'
							icon='CalendarToday'
							color='dark'
							style={{ marginTop: '-25px', zIndex: '0', marginLeft: '150px' }}
						/>
					</InputGroup>
				</div>
			</div>
		</>
	);
};

const fetchDataAsyncSelect = async (find, type, isDefault = false) => {
	if (find?.length >= 3 || isDefault) {
		if (type === 'bank') {
			const payload = {
				search: find,
			};
			const result = ProfileModule.getBank(new URLSearchParams(payload));
			if (result) {
				return result;
			}
		}
		if (type === 'method') {
			const payload = {
				search: find,
			};
			const result = ProfileModule.getPaymentMethod(new URLSearchParams(payload));
			if (result) {
				return result;
			}
		}
		if (type === 'terms') {
			const payload = {
				search: find,
			};
			const result = ProfileModule.getPaymentTerms(new URLSearchParams(payload));
			if (result) {
				return result;
			}
		}
	}
	return [];
};

const Main = () => {
	const { username } = getRequester();
	const [title] = useState({ title: 'Profile' });
	const [initial, setInitValues] = useState({
		name: '',
		email: '',
		street: '',
		username: '',
		no_npwp: '',
		file_npwp: '',
		no_tdp: '',
		file_tdp: '',
		exp_date_tdp: '',
		no_siup: '',
		file_siup: '',
		exp_date_siup: '',
		onedrive_id_npwp: '',
		onedrive_id_tdp: '',
	});
	const [activeTab, setActiveTab] = useState('profile');
	const [bankList, setBankList] = useState([]);
	const [methodList, setMethodList] = useState([]);
	const [termsList, setTermsList] = useState([]);
	const [emailList, setEmailList] = useState([]);
	const [loading, setLoading] = useState(false);

	const fecthData = () => {
		setLoading(true);
		const params = {
			username,
		};
		ProfileModule.detail(new URLSearchParams(params))
			.then((res) => {
				res.no_npwp = res?.npwp?.no;
				res.onedrive_id_npwp = res?.npwp?.onedrive_id;
				res.no_tdp = res?.tdp?.no;
				res.onedrive_id_tdp = res?.tdp?.onedrive_id;
				res.exp_date_tdp = res?.tdp?.exp_date ? new Date(res?.tdp?.exp_date) : '';
				res.no_siup = res?.siup?.no;
				res.onedrive_id_siup = res?.siup?.onedrive_id;
				res.exp_date_siup = res?.siup?.exp_date ? new Date(res?.siup?.exp_date) : '';
				res.bank_name = res?.bank_vendor?.bank_name;
				res.bank_branch = res?.bank_vendor?.bank_branch;
				res.account_number = res?.bank_vendor?.account_number;
				res.account_holder = res?.bank_vendor?.account_holder;
				res.email_list = res.email;
				res.npwp_filename = res?.npwp?.filename;
				res.tdp_filename = res?.tdp?.filename;
				res.siup_filename = res?.siup?.filename;

				setEmailList(res.email);
				setInitValues(res);
			})
			.catch((err) => {
				showNotification('Warning!', err, 'danger');
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const optionsList = async () => {
		const listbank = await fetchDataAsyncSelect('', 'bank', true);
		const listmethod = await fetchDataAsyncSelect('', 'method', true);
		const listterms = await fetchDataAsyncSelect('', 'terms', true);
		setBankList(listbank);
		setMethodList(listmethod);
		setTermsList(listterms);
	};

	const BUTTON_LIST = [
		{ label: 'Profile Details', key: 'profile', icon: 'Person' },
		{ label: 'Document Attachments', key: 'document', icon: 'ListAlt' },
	];

	useEffect(() => {
		fecthData();
		optionsList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const formik = useFormik({
		initialValues: initial,
		enableReinitialize: true,
		onSubmit: (values) => {
			// eslint-disable-next-line no-alert
			handleSubmit(values, fecthData, setLoading, formik);
		},
	});

	useEffect(() => {
		formik.resetForm();
		fecthData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeTab]);

	return (
		<PageWrapper title={title.title}>
			<PageLayoutHeader />
			<ModalLoading setLoading={setLoading} loading={loading} />
			<Page container='fluid'>
				<div className='col-12 d-flex mb-3 align-items-center justify-content-center'>
					<div className='rounded' style={{ width: '85%' }}>
						<div className='row'>
							<div className='col-3'>
								<Card stretch>
									<CardHeader borderSize={1}>
										<CardLabel icon='Person' iconColor='info'>
											<CardTitle>Profile</CardTitle>
											<CardSubTitle>Personal Information</CardSubTitle>
										</CardLabel>
									</CardHeader>
									<CardBody>
										<div className='row g-3'>
											{BUTTON_LIST.map((btn) => (
												<div className='col-12'>
													<Button
														icon={btn.icon}
														color='info'
														className='w-100 p-3'
														isLight={btn.key !== activeTab}
														onClick={() => setActiveTab(btn.key)}>
														{btn.label}
													</Button>
												</div>
											))}
										</div>
									</CardBody>
								</Card>
							</div>
							<div className='col-9'>
								<Card style={{ minHeight: '530px', maxHeight: '700px' }}>
									{activeTab === 'profile' && (
										<>
											<CardHeader borderSize={1}>
												<CardLabel
													icon='Person'
													iconColor='info'
													style={{ paddingBottom: '5px' }}>
													<CardTitle style={{ marginTop: '5px' }}>
														Profile Detail
													</CardTitle>
												</CardLabel>
											</CardHeader>
											<CardBody isScrollable>
												<GeneralData
													formik={formik}
													emailList={emailList}
													setEmailList={setEmailList}
												/>
												<BankVendor formik={formik} bankList={bankList} />
												<CompanyCodeData
													formik={formik}
													methodList={methodList}
													termsList={termsList}
												/>
												<PurchasingOrganizationData formik={formik} />
											</CardBody>
										</>
									)}
									{activeTab === 'document' && (
										<>
											<CardHeader borderSize={1}>
												<CardLabel
													icon='ListAlt'
													iconColor='info'
													style={{ paddingBottom: '5px' }}>
													<CardTitle style={{ marginTop: '5px' }}>
														Document Attachments
													</CardTitle>
												</CardLabel>
											</CardHeader>
											<CardBody>
												<AttactmentData formik={formik} />
											</CardBody>
										</>
									)}
									<CardFooter>
										<div className='col-12'>
											<Button
												className='float-end'
												type='button'
												onClick={formik.handleSubmit}
												color='info'>
												Save
											</Button>
										</div>
									</CardFooter>
								</Card>
							</div>
						</div>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default Main;
