import Service from '../../services/sodiq/registrasi_vendor.service';

const register = (payload) => {
	return Service.registerVendor(payload).then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};

const verify = (payload) => {
	return Service.verify(payload).then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};

export default { register, verify };
