import jwt_decode from 'jwt-decode';
import history from '../helpers/history';

const checkSessionExpired = async (token) => {
	let isExpired = false;
	const decodedToken = jwt_decode(token, process.env.REACT_APP_PRIVATE_KEY);

	const dateNow = new Date().getTime();
	const selisih = decodedToken.exp * 1000 - dateNow;
	if (decodedToken.exp * 1000 < dateNow) isExpired = true;
	return { isExpired, selisih };
};
export default async function refreshToken() {
	const token =
		JSON.parse(localStorage.getItem('accessToken')) ??
		JSON.parse(localStorage.getItem('transporterToken'));
	const publicKey = process.env.REACT_APP_PUBLIC_KEY;

	const applicationToken = JSON.parse(localStorage.getItem('appToken'));
	if (token) {
		let userToken = '';
		if (token?.accessToken) {
			const showExpired = checkSessionExpired(token.accessToken);
			if (showExpired.isExpired) {
				localStorage.setItem('message', 'Session is expired. Please login again');
				localStorage.removeItem('appToken');
				localStorage.removeItem('accessToken');
				history.push('/login');
				window.location.reload();
			}

			userToken = token.accessToken;
		} else if (token?.transporterToken) {
			const showExpired = checkSessionExpired(token.transporterToken);
			if (showExpired.isExpired) {
				localStorage.setItem('message', 'Session is expired. Please login again');
				localStorage.removeItem('appToken');
				localStorage.removeItem('transporterToken');
				history.push('/transporter/login');
				window.location.reload();
			}

			userToken = token.transporterToken;
		}

		if (userToken)
			return {
				'x-public-key': publicKey,
				'x-application-token': `Bearer ${applicationToken}`,
				'x-user-token': `Bearer ${userToken}`,
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*',
			};
	}

	return {
		'x-public-key': publicKey,
		'x-application-token': `Bearer ${applicationToken}`,
		'Content-Type': 'application/json',
		'Access-Control-Allow-Origin': '*',
	};
}
