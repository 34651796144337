import axios from 'axios';
import authHeader from './auth-header';

const API_URL_DEFAULT = process.env.REACT_APP_API;

const read = async (query) => {
	return axios.get(`${API_URL_DEFAULT}file`, {
		headers: await authHeader(),
		params: query,
	});
};
const readByID = async (onedrive_id) => {
	return axios.get(`${API_URL_DEFAULT}file/${onedrive_id}`, {
		headers: await authHeader(),
	});
};


const directRead = async (query) => {
	return axios.get(`${API_URL_DEFAULT}file/direct?${query}`, {
		headers: await authHeader(),
		params: query,
	});
};

export default { read, directRead ,readByID}; 
