import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import QRCode from 'react-qr-code';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import Page from '../../../../layout/Page/Page';
import Accordion, { AccordionItem } from '../../../../components/bootstrap/Accordion';
import Timeline, { TimelineItem } from '../../../../components/extras/Timeline';
import Button from '../../../../components/bootstrap/Button';
import ListGroup, { ListGroupItem } from '../../../../components/bootstrap/ListGroup';
import Input from '../../../../components/bootstrap/forms/Input';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import useDarkMode from '../../../../hooks/useDarkMode';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../../../components/bootstrap/Modal';
import Checks, { ChecksGroup } from '../../../../components/bootstrap/forms/Checks';
import Textarea from '../../../../components/bootstrap/forms/Textarea';
import TransporterModule from '../../../../modules/afif/TransporterModule';
import showNotification from '../../../../components/extras/showNotification';
import { convertToFormData, mimeToExtension } from '../../../../helpers/helpers';
import LoadingModal from '../../../../components/custom/LoadingModal';
import Badge from '../../../../components/bootstrap/Badge';
import FileModal from '../../../../components/custom/FileModal';
import FileModule from '../../../../modules/FileModule';
import './DashboardTransporter.scss';

const updateCustomerFunction = (values) => {
	return new Promise((resolve, reject) => {
		try {
			TransporterModule.updateCustomer(values)
				.then(() => {
					Swal.fire({
						heightAuto: false,
						title: 'Information!',
						text: 'Your data has been updated successfully',
						icon: 'success',
					});
					resolve({ error: false, message: 'successfully' });
				})
				.catch((err) => {
					Swal.fire({ heightAuto: false, title: 'Warning!', text: err, icon: 'error' });
					reject(new Error(err));
				});
		} catch (e) {
			reject(new Error(e.message));
		}
	});
};

const updateTrackingFunction = (values) => {
	return new Promise((resolve, reject) => {
		try {
			TransporterModule.updateTracking(values)
				.then(() => {
					Swal.fire({
						heightAuto: false,
						title: 'Information!',
						text: 'Your data has been updated successfully',
						icon: 'success',
					});
					resolve({ error: false, message: 'successfully' });
				})
				.catch((err) => {
					Swal.fire({ heightAuto: false, title: 'Warning!', text: err, icon: 'error' });
					reject(new Error(err));
				});
		} catch (e) {
			reject(new Error(e.message));
		}
	});
};

const FormCustom = ({
	type,
	id,
	title,
	closeLabel,
	submitLabel,
	initialValues,
	handleCustomSubmit,
	isOpen,
	setIsOpen,
}) => {
	const { darkModeStatus } = useDarkMode();
	const formik = useFormik({
		initialValues,
		validate: (values) => {
			const errors = {};
			if (type === 'location') {
				if (!values?.location) errors.location = 'Required';

				if (values.photo) {
					if (!['image/jpeg', 'image/jpg', 'image/png'].includes(values.photo.type))
						errors.photo = 'Invalid File Extension';
					else if (values.photo.size > 2e6) errors.photo = 'Invalid File Size';
				}
			} else {
				if (!values?.jumlah_sesuai) errors.jumlah_sesuai = 'Required';
				if (!values?.ada_yang_ditolak) errors.ada_yang_ditolak = 'Required';
				if (!values?.tanggal_kedatangan) errors.tanggal_kedatangan = 'Required';
				if (!values?.jam_kedatangan) errors.jam_kedatangan = 'Required';
				if (!values?.tanggal_bongkar_muat) errors.tanggal_bongkar_muat = 'Required';
				if (!values?.jam_bongkar_muat) errors.jam_bongkar_muat = 'Required';

				if (!values?.photo_kedatangan_ttd) errors.photo_kedatangan_ttd = 'Required';
				else if (
					!['image/jpeg', 'image/jpg', 'image/png'].includes(
						values.photo_kedatangan_ttd.type,
					)
				)
					errors.photo_kedatangan_ttd = 'Invalid File Extension';
				else if (values.photo_kedatangan_ttd.size > 2e6)
					errors.photo_kedatangan_ttd = 'Invalid File Size';

				if (!values?.surat_jalan_ttd) errors.surat_jalan_ttd = 'Required';
				else if (
					!['image/jpeg', 'image/jpg', 'application/pdf'].includes(
						values.surat_jalan_ttd.type,
					)
				)
					errors.surat_jalan_ttd = 'Invalid File Extension';
				else if (values.surat_jalan_ttd.size > 2e6)
					errors.surat_jalan_ttd = 'Invalid File Size';
			}

			return errors;
		},
		onReset: () => {
			setIsOpen(false);
		},
		onSubmit: (values, { setErrors, setSubmitting }) => {
			try {
				Swal.fire({
					heightAuto: false,
					title: 'Are you sure?',
					text: 'Please check your entries!',
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Yes',
					reverseButtons: true,
				}).then((result) => {
					if (result.isConfirmed) {
						handleCustomSubmit(values, formik.handleReset);
					}
				});
			} catch (err) {
				setErrors({ submit: err.message });
				Swal.fire({
					heightAuto: false,
					title: 'Information!',
					text: 'Please check your entries again!',
					icon: 'error',
				});
			} finally {
				setSubmitting(false);
			}
		},
		enableReinitialize: true,
	});

	const inputComponent = useMemo(
		() =>
			type === 'location' ? (
				<>
					<div className='col-12 py-3'>
						<FormGroup label='Nama Lokasi'>
							<Input
								id='InputNamaLokasi'
								type='text'
								autoComplete='off'
								name='location'
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.location}
								isValid={formik.isValid}
								isTouched={formik.touched.location}
								invalidFeedback={formik.errors.location}
							/>
						</FormGroup>
					</div>
					<div className='col-12 py-3'>
						<FormGroup
							label='Foto (Opsional)'
							formText='Maks 2MB dengan ekstensi JPEG, PNG'>
							<Input
								id='InputFoto'
								type='file'
								accept='image/png,image/jpeg,image/jpg'
								autoComplete='off'
								onChange={(e) => formik.setFieldValue('photo', e.target.files[0])}
								onBlur={formik.handleBlur}
								isValid={formik.isValid}
								isTouched={formik.touched.photo}
								invalidFeedback={formik.errors.photo}
							/>
						</FormGroup>
					</div>
				</>
			) : (
				<>
					<div className='col-12 py-3'>
						<FormGroup label='Jumlah Sesuai?'>
							<ChecksGroup
								id='RadioJumlahSesuai'
								isValid={formik.isValid}
								isTouched={formik.touched.jumlah_sesuai}
								invalidFeedback={formik.errors.jumlah_sesuai}
								isInline>
								<Checks
									id='RadioButtonYa'
									type='radio'
									label='Ya'
									name='jumlah_sesuai'
									value='ya'
									onChange={formik.handleChange}
									checked={formik.values.jumlah_sesuai}
								/>
								<Checks
									id='RadioButtonTidak'
									type='radio'
									label='Tidak'
									name='jumlah_sesuai'
									value='tidak'
									onChange={formik.handleChange}
									checked={formik.values.jumlah_sesuai}
								/>
							</ChecksGroup>
						</FormGroup>
					</div>
					<div className='col-12 py-3'>
						<FormGroup label='Ada yang ditolak?'>
							<ChecksGroup
								id='RadioAdaYangDitolak'
								isValid={formik.isValid}
								isTouched={formik.touched.ada_yang_ditolak}
								invalidFeedback={formik.errors.ada_yang_ditolak}
								isInline>
								<Checks
									id='RadioButtonYa'
									type='radio'
									label='Ya'
									name='ada_yang_ditolak'
									value='ya'
									onChange={formik.handleChange}
									checked={formik.values.ada_yang_ditolak}
								/>
								<Checks
									id='RadioButtonTidak'
									type='radio'
									label='Tidak'
									name='ada_yang_ditolak'
									value='tidak'
									onChange={formik.handleChange}
									checked={formik.values.ada_yang_ditolak}
								/>
							</ChecksGroup>
						</FormGroup>
					</div>
					<div className='col-6 py-3'>
						<FormGroup label='Tgl. Kedatangan'>
							<Input
								id='InputTglKedatangan'
								type='date'
								autoComplete='off'
								name='tanggal_kedatangan'
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.tanggal_kedatangan}
								isValid={formik.isValid}
								isTouched={formik.touched.tanggal_kedatangan}
								invalidFeedback={formik.errors.tanggal_kedatangan}
							/>
						</FormGroup>
					</div>
					<div className='col-6 py-3'>
						<FormGroup label='Waktu Kedatangan'>
							<Input
								id='InputWaktuKedatangan'
								type='time'
								autoComplete='off'
								name='jam_kedatangan'
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.jam_kedatangan}
								isValid={formik.isValid}
								isTouched={formik.touched.jam_kedatangan}
								invalidFeedback={formik.errors.jam_kedatangan}
							/>
						</FormGroup>
					</div>
					<div className='col-6 py-3'>
						<FormGroup label='Tgl. Bongkar Muat'>
							<Input
								id='InputTglBongkarMuat'
								type='date'
								autoComplete='off'
								name='tanggal_bongkar_muat'
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.tanggal_bongkar_muat}
								isValid={formik.isValid}
								isTouched={formik.touched.tanggal_bongkar_muat}
								invalidFeedback={formik.errors.tanggal_bongkar_muat}
							/>
						</FormGroup>
					</div>
					<div className='col-6 py-3'>
						<FormGroup label='Waktu Bongkar Muat'>
							<Input
								id='InputWaktuBongkarMuat'
								type='time'
								autoComplete='off'
								name='jam_bongkar_muat'
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.jam_bongkar_muat}
								isValid={formik.isValid}
								isTouched={formik.touched.jam_bongkar_muat}
								invalidFeedback={formik.errors.jam_bongkar_muat}
							/>
						</FormGroup>
					</div>
					<div className='col-12 py-3'>
						<FormGroup
							label='Upload Foto'
							formText='Maks. ukuruan 2MB dengan ekstensi JPEG, PNG'>
							<Input
								id='InputUploadFoto'
								type='file'
								accept='image/png,image/jpeg,image/jpg'
								autoComplete='off'
								onChange={(e) =>
									formik.setFieldValue('photo_kedatangan_ttd', e.target.files[0])
								}
								onBlur={formik.handleBlur}
								isValid={formik.isValid}
								isTouched={formik.touched.photo_kedatangan_ttd}
								invalidFeedback={formik.errors.photo_kedatangan_ttd}
							/>
						</FormGroup>
					</div>
					<div className='col-12 py-3'>
						<FormGroup
							label='Upload Surat Jalan'
							formText='Maks. ukuruan 2MB dengan ekstensi JPEG, PDF'>
							<Input
								id='InputUploadSuratJalan'
								type='file'
								accept='application/pdf,image/jpeg,image/jpg'
								autoComplete='off'
								onChange={(e) =>
									formik.setFieldValue('surat_jalan_ttd', e.target.files[0])
								}
								onBlur={formik.handleBlur}
								isValid={formik.isValid}
								isTouched={formik.touched.surat_jalan_ttd}
								invalidFeedback={formik.errors.surat_jalan_ttd}
							/>
						</FormGroup>
					</div>
					<div className='col-12 py-3'>
						<FormGroup label='Catatan (Opsional)' className='col-md-12'>
							<Textarea
								id='TextareaCatatan'
								name='note_kedatangan'
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.note_kedatangan}
								isValid={formik.isValid}
								isTouched={formik.touched.note_kedatangan}
								invalidFeedback={formik.errors.note_kedatangan}
								autoComplete='off'
							/>
						</FormGroup>
					</div>
				</>
			),
		[formik, type],
	);

	return (
		<Modal
			id={id}
			titleId={title}
			isOpen={isOpen}
			setIsOpen={setIsOpen}
			isFocus={false}
			enableEscape={false}
			isStaticBackdrop
			isCentered>
			<ModalHeader setIsOpen={setIsOpen}>
				<ModalTitle>{title}</ModalTitle>
			</ModalHeader>
			<ModalBody>
				<form noValidate onSubmit={formik.handleSubmit} className='row px-2'>
					{inputComponent}
					<div className='d-flex justify-content-end pt-2 pb-1'>
						<Button
							id={`Button${closeLabel}`}
							color='primary'
							isOutline
							type='reset'
							onClick={formik.handleReset}
							className='m-1 border-0'>
							{closeLabel}
						</Button>

						<Button
							id={`Button${submitLabel}`}
							color='primary'
							isLight={darkModeStatus}
							type='submit'
							onClick={formik.handleSubmit}
							className='m-1'>
							{submitLabel}
						</Button>
					</div>
				</form>
			</ModalBody>
		</Modal>
	);
};
FormCustom.propTypes = {
	type: PropTypes.string,
	id: PropTypes.string,
	title: PropTypes.string,
	closeLabel: PropTypes.string,
	submitLabel: PropTypes.string,
	initialValues: PropTypes.oneOfType([PropTypes.object]),
	handleCustomSubmit: PropTypes.func,
	isOpen: PropTypes.bool,
	setIsOpen: PropTypes.func,
};
FormCustom.defaultProps = {
	type: '',
	id: 'FormCustomModal',
	title: 'Form',
	closeLabel: 'Cancel',
	submitLabel: 'Submit',
	initialValues: {},
	handleCustomSubmit: () => {},
	isOpen: false,
	setIsOpen: () => false,
};

moment.locale('id');
const Dashboard = () => {
	const [data, setData] = useState(null);

	const [fileOpen, setFileOpen] = useState(false);
	const [file, setFile] = useState(null);

	const [loadingModal, setLoadingModal] = useState(false);
	const [formOpen, setFormOpen] = useState(false);
	const [formOptions, setFormOptions] = useState({
		id: 'FormCustomModal',
		title: 'Form',
		closeLabel: 'Cancel',
		submitLabel: 'Submit',
		initialValues: {},
		submitFunction: () => {},
	});

	const fetchData = async () => {
		try {
			const res = await TransporterModule.getCurrentTransporter();
			setData(res);
		} catch (err) {
			showNotification('Warning!', err, 'danger');
		}
	};

	const replaceDocumentType = (type) => {
		if (/(?=_)/.test(type))
			return type
				.split('_')
				.map((str) => {
					return str.slice(0, 1).toUpperCase() + str.slice(1, str.length);
				})
				.join(' ');

		return type.toUpperCase();
	};

	const downloadUniqueCode = () => {
		const barcode = document.getElementById('SvgBarcode');
		const svgString = new XMLSerializer().serializeToString(barcode);

		const canvas = document.createElement('canvas');
		const ctx = canvas.getContext('2d');

		const svgBlob = new Blob([svgString], { type: 'image/svg+xml' });
		const url = URL.createObjectURL(svgBlob);
		const img = new Image();
		img.src = url;
		img.onload = () => {
			canvas.width = img.width;
			canvas.height = img.height;
			ctx.drawImage(img, 0, 0);
			const jpg = canvas.toDataURL('image/jpg');
			const a = document.createElement('a');
			a.href = jpg;
			a.download = 'unique_code.jpg';
			a.click();
		};
	};

	const copyUniqueCode = () => {
		navigator.clipboard.writeText(data.unique_code);
	};

	const handleArrival = (index) => {
		if (formOptions.type !== 'arrival')
			setFormOptions((prev) => ({
				...prev,
				type: 'arrival',
				id: 'FormKedatangan',
				title: 'Formulir Kedatangan',
				closeLabel: 'Tutup',
				submitLabel: 'Simpan',
				initialValues: {
					index,
					jumlah_sesuai: '',
					ada_yang_ditolak: '',
					tanggal_kedatangan: '',
					jam_kedatangan: '',
					tanggal_bongkar_muat: '',
					jam_bongkar_muat: '',
					photo_kedatangan_ttd: null,
					surat_jalan_ttd: null,
					note_kedatangan: '',
				},
				submitFunction: (values, handleReset) => {
					setLoadingModal(true);
					values = convertToFormData(values, ['photo_kedatangan_ttd', 'surat_jalan_ttd']);
					updateCustomerFunction(values)
						.then(() => {
							fetchData();
							handleReset();
						})
						.catch(() => {})
						.finally(() => {
							setLoadingModal(false);
						});
				},
			}));
		else setFormOpen(true);
	};

	const handleLocation = () => {
		if (formOptions.type !== 'location')
			setFormOptions((prev) => ({
				...prev,
				type: 'location',
				id: 'FormPerbaruiLokasi',
				title: 'Perbarui Lokasi',
				closeLabel: 'Batal',
				submitLabel: 'Simpan',
				initialValues: {
					location: '',
					photo: null,
				},
				submitFunction: (values, handleReset) => {
					setLoadingModal(true);
					values = convertToFormData(values, ['photo']);
					updateTrackingFunction(values)
						.then(() => {
							fetchData();
							handleReset();
						})
						.catch(() => {})
						.finally(() => {
							setLoadingModal(false);
						});
				},
			}));
		else setFormOpen(true);
	};

	const handleFile = (onedrive_id, fileName) => {
		return FileModule.directRead({ onedrive_id })
			.then((response) => {
				response.fileName = fileName;
				response.src = `data:${response.mimetype};base64,${response.file}`;
				response.extension = mimeToExtension(response.mimetype);

				setFile(response);
				setFileOpen(true);
			})
			.catch(() => {
				showNotification('Information!', 'Failed preview template', 'danger');
			});
	};

	useEffect(() => {
		if (formOptions.type) setFormOpen(true);
	}, [formOptions]);

	useEffect(() => {
		fetchData();
	}, []);

	return (
		<PageWrapper title='Transporter' className='mobile-preview-container'>
			<Page className='mobile-preview'>
				{!!data && (
					<>
						<header className='text-end'>
							<h6>Halo, {data.information.driver.name}</h6>
						</header>
						<div className='row gap-3 py-5'>
							<div className='col-12'>
								<Accordion id='AccordionFirst' activeItemId={false}>
									<AccordionItem
										id='AccordionItemInformasiPengiriman'
										title='Informasi Pengiriman'
										icon='InfoCircle'>
										<Accordion
											id='AccordionCustomers'
											activeItemId={false}
											isFlush>
											{data.customers.map((customer, i) => (
												<AccordionItem
													id={`AccordionItem${customer.customer_name.toUpperCase()}-${i}`}
													title={customer.customer_name.toUpperCase()}>
													<ListGroup
														className='w-100 items-2'
														isHorizontal
														isFlush>
														<ListGroupItem className='fw-bold border-0'>
															No. Dokumen
														</ListGroupItem>
														<ListGroupItem
															className='border-0'
															style={{ whiteSpace: 'pre-line' }}>
															{customer.document
																.map(
																	(doc) =>
																		`${doc.doc_type}: ${doc.doc_no}`,
																)
																.join('\n')}
														</ListGroupItem>
													</ListGroup>
													<ListGroup
														className='w-100 items-2'
														isHorizontal
														isFlush>
														<ListGroupItem className='fw-bold border-0'>
															ETD
														</ListGroupItem>
														<ListGroupItem className='border-0'>
															{moment(customer.etd).format(
																'DD MMMM YYYY, HH:mm WIB',
															)}
														</ListGroupItem>
													</ListGroup>
													<ListGroup
														className='w-100 items-2'
														isHorizontal
														isFlush>
														<ListGroupItem className='fw-bold border-0'>
															ETA
														</ListGroupItem>
														<ListGroupItem className='border-0'>
															{moment(customer.eta).format(
																'DD MMMM YYYY, HH:mm WIB',
															)}
														</ListGroupItem>
													</ListGroup>
													<ListGroup
														className='w-100 items-2'
														isHorizontal
														isFlush>
														<ListGroupItem className='fw-bold border-0'>
															Customer/Supplier
														</ListGroupItem>
														<ListGroupItem className='border-0'>
															{customer.customer_name.toUpperCase()}
														</ListGroupItem>
													</ListGroup>
													<ListGroup
														className='w-100 items-2'
														isHorizontal
														isFlush>
														<ListGroupItem className='fw-bold border-0'>
															Alamat
														</ListGroupItem>
														<ListGroupItem className='border-0'>
															{customer.address}
														</ListGroupItem>
													</ListGroup>
													<ListGroup
														className='w-100 items-2'
														isHorizontal
														isFlush>
														<ListGroupItem className='fw-bold border-0'>
															Produk
														</ListGroupItem>
														<ListGroupItem
															className='border-0'
															style={{ whiteSpace: 'pre-line' }}>
															{customer.product
																.map(
																	(prod) =>
																		`(${prod.product_code}) ${prod.product_name} ${prod.qty} ${prod.uom}`,
																)
																.join('\n')}
														</ListGroupItem>
													</ListGroup>
													<ListGroup
														className='w-100 items-2'
														isHorizontal
														isFlush>
														<ListGroupItem className='fw-bold border-0'>
															Jumlah Total
														</ListGroupItem>
														<ListGroupItem className='border-0'>
															{Intl.NumberFormat('id-ID').format(
																customer.total_qty,
															)}
														</ListGroupItem>
													</ListGroup>
													<ListGroup
														className='w-100 items-2'
														isHorizontal
														isFlush>
														<ListGroupItem className='fw-bold border-0'>
															Satuan
														</ListGroupItem>
														<ListGroupItem className='border-0'>
															{customer.uom}
														</ListGroupItem>
													</ListGroup>
													{customer?.jam_kedatangan ? (
														<>
															<ListGroup
																className='w-100 items-2'
																isHorizontal
																isFlush>
																<ListGroupItem className='fw-bold border-0'>
																	Status
																</ListGroupItem>
																<ListGroupItem className='border-0'>
																	<Badge color='success'>
																		Done
																	</Badge>
																</ListGroupItem>
															</ListGroup>
															<ListGroup
																className='w-100 items-2'
																isHorizontal
																isFlush>
																<ListGroupItem className='fw-bold border-0'>
																	No. Loading Deck
																</ListGroupItem>
																<ListGroupItem className='border-0'>
																	{customer.sloc}
																</ListGroupItem>
															</ListGroup>
															<ListGroup
																className='w-100 items-2'
																isHorizontal
																isFlush>
																<ListGroupItem className='fw-bold border-0'>
																	Jumlah Sesuai
																</ListGroupItem>
																<ListGroupItem className='border-0'>
																	{customer.jumlah_sesuai
																		.slice(0, 1)
																		.toUpperCase() +
																		customer.jumlah_sesuai.slice(
																			1,
																			customer.jumlah_sesuai
																				.length,
																		)}
																</ListGroupItem>
															</ListGroup>
															<ListGroup
																className='w-100 items-2'
																isHorizontal
																isFlush>
																<ListGroupItem className='fw-bold border-0'>
																	Ada yang Ditolak
																</ListGroupItem>
																<ListGroupItem className='border-0'>
																	{customer.ada_yang_ditolak
																		.slice(0, 1)
																		.toUpperCase() +
																		customer.ada_yang_ditolak.slice(
																			1,
																			customer
																				.ada_yang_ditolak
																				.length,
																		)}
																</ListGroupItem>
															</ListGroup>
															<ListGroup
																className='w-100 items-2'
																isHorizontal
																isFlush>
																<ListGroupItem className='fw-bold border-0'>
																	Waktu Kedatangan
																</ListGroupItem>
																<ListGroupItem className='border-0'>
																	{moment(
																		`${customer.tanggal_kedatangan} ${customer.jam_kedatangan}`,
																	).format(
																		'DD MMM YYYY, HH:mm',
																	)}{' '}
																	WIB
																</ListGroupItem>
															</ListGroup>
															<ListGroup
																className='w-100 items-2'
																isHorizontal
																isFlush>
																<ListGroupItem className='fw-bold border-0'>
																	Jadwal Bongkar Muat
																</ListGroupItem>
																<ListGroupItem className='border-0'>
																	{moment(
																		`${customer.tanggal_bongkar_muat} ${customer.jam_bongkar_muat}`,
																	).format(
																		'DD MMM YYYY, HH:mm',
																	)}{' '}
																	WIB
																</ListGroupItem>
															</ListGroup>
															<ListGroup
																className='w-100 items-2'
																isHorizontal
																isFlush>
																<ListGroupItem className='fw-bold border-0 align-content-center'>
																	Foto Kedatangan
																</ListGroupItem>
																<ListGroupItem className='border-0'>
																	<Button
																		size='sm'
																		isOutline
																		color='primary'
																		icon='EyeFill'
																		onClick={() =>
																			handleFile(
																				customer.photo_kedatangan_ttd,
																				'photo_kedatangan_ttd',
																			)
																		}>
																		Lihat Foto
																	</Button>
																</ListGroupItem>
															</ListGroup>
															<ListGroup
																className='w-100 items-2'
																isHorizontal
																isFlush>
																<ListGroupItem className='fw-bold border-0 align-content-center'>
																	Surat Jalan
																</ListGroupItem>
																<ListGroupItem className='border-0'>
																	<Button
																		size='sm'
																		isOutline
																		color='primary'
																		icon='EyeFill'
																		onClick={() =>
																			handleFile(
																				customer.surat_jalan_ttd,
																				'surat_jalan_ttd',
																			)
																		}>
																		Lihat Dokumen
																	</Button>
																</ListGroupItem>
															</ListGroup>
															<ListGroup
																className='w-100 items-2'
																isHorizontal
																isFlush>
																<ListGroupItem className='fw-bold border-0'>
																	Catatan
																</ListGroupItem>
																<ListGroupItem className='border-0'>
																	{customer?.note_kedatangan
																		? customer.note_kedatangan
																		: '-'}
																</ListGroupItem>
															</ListGroup>
														</>
													) : (
														<>
															<ListGroup
																className='w-100 items-2'
																isHorizontal
																isFlush>
																<ListGroupItem className='fw-bold border-0'>
																	No. Loading Deck
																</ListGroupItem>
																<ListGroupItem className='border-0'>
																	{customer.sloc}
																</ListGroupItem>
															</ListGroup>
															<Button
																color='primary'
																className='w-100 my-3'
																onClick={() => handleArrival(i)}>
																Formulir Kedatangan
															</Button>
														</>
													)}
												</AccordionItem>
											))}
										</Accordion>
									</AccordionItem>
								</Accordion>
							</div>
							<div className='col-12'>
								<Accordion id='AccordionSecond' activeItemId={false}>
									<AccordionItem
										id='AccordionItemTracking'
										title='Tracking'
										icon='GeoAlt'>
										<Timeline widthLabel='10rem'>
											{data.tracking.map((track) => {
												const trackCreatedAt = moment(track.created_at);
												return (
													<TimelineItem
														label={trackCreatedAt.format('LT')}
														subLabel={trackCreatedAt.format(
															'(DD MMM YYYY)',
														)}
														labelClassName='text-end'>
														<>
															<p className='mb-2'>{track.location}</p>
															{!!track?.photo && (
																<Button
																	isOutline
																	color='primary'
																	icon='EyeFill'
																	size='sm'
																	onClick={() =>
																		handleFile(
																			track.photo,
																			`photo_${track.location.toLowerCase()}`,
																		)
																	}>
																	Lihat Foto
																</Button>
															)}
														</>
													</TimelineItem>
												);
											})}
										</Timeline>
										<Button
											color='primary'
											className='w-100 my-3'
											onClick={handleLocation}>
											Perbarui Lokasi
										</Button>
									</AccordionItem>
								</Accordion>
							</div>
							<div className='col-12'>
								<Accordion id='AccordionThird' activeItemId={false}>
									<AccordionItem
										id='AccordionItemInformasiSopir&Kendaraan'
										title='Informasi Sopir & Kendaraan'
										icon='Truck'>
										<ListGroup className='w-100 items-2' isHorizontal isFlush>
											<ListGroupItem className='fw-bold border-0'>
												Nama Driver
											</ListGroupItem>
											<ListGroupItem className='border-0'>
												{data.information.driver.name}
											</ListGroupItem>
										</ListGroup>
										<ListGroup className='w-100 items-2' isHorizontal isFlush>
											<ListGroupItem className='fw-bold border-0'>
												No. Telepon
											</ListGroupItem>
											<ListGroupItem className='border-0'>
												{data.information.driver.no_telp}
											</ListGroupItem>
										</ListGroup>
										<ListGroup className='w-100 items-2' isHorizontal isFlush>
											<ListGroupItem className='fw-bold border-0'>
												Kartu Identitas
											</ListGroupItem>
											<ListGroupItem className='border-0'>
												{data.information.driver.identity_card}
											</ListGroupItem>
										</ListGroup>
										<ListGroup className='w-100 items-2' isHorizontal isFlush>
											<ListGroupItem className='fw-bold border-0 align-content-center'>
												Foto Kartu Identitas
											</ListGroupItem>
											<ListGroupItem className='border-0'>
												<Button
													size='sm'
													isOutline
													color='primary'
													icon='EyeFill'
													onClick={() =>
														handleFile(
															data.information.driver.identity_file,
															'identity_file',
														)
													}>
													Lihat Foto
												</Button>
											</ListGroupItem>
										</ListGroup>
										<ListGroup className='w-100 items-2' isHorizontal isFlush>
											<ListGroupItem className='fw-bold border-0'>
												Vehicle Type
											</ListGroupItem>
											<ListGroupItem className='border-0'>
												{data.information.vehicle.type}
											</ListGroupItem>
										</ListGroup>
										<ListGroup className='w-100 items-2' isHorizontal isFlush>
											<ListGroupItem className='fw-bold border-0'>
												No. Polisi
											</ListGroupItem>
											<ListGroupItem className='border-0'>
												{data.information.vehicle.license_plate}
											</ListGroupItem>
										</ListGroup>
										<ListGroup className='w-100 items-2' isHorizontal isFlush>
											<ListGroupItem className='fw-bold border-0'>
												Seal Number
											</ListGroupItem>
											<ListGroupItem className='border-0'>
												{data.information.vehicle.seal_number}
											</ListGroupItem>
										</ListGroup>
										<ListGroup className='w-100 items-2' isHorizontal isFlush>
											<ListGroupItem className='fw-bold border-0'>
												No. Cont/IsoTank
											</ListGroupItem>
											<ListGroupItem className='border-0'>
												{data.information.vehicle.no_container}
											</ListGroupItem>
										</ListGroup>
										<ListGroup className='w-100 items-2' isHorizontal isFlush>
											<ListGroupItem className='fw-bold border-0'>
												Catatan
											</ListGroupItem>
											<ListGroupItem className='border-0'>
												{data.information.note}
											</ListGroupItem>
										</ListGroup>
									</AccordionItem>
								</Accordion>
							</div>
							<div className='col-12'>
								<Accordion id='AccordionFourth' activeItemId={false}>
									<AccordionItem
										id='AccordionItemDokumenPengiriman'
										title='Dokumen Pengiriman'
										icon='FileEarmarkText'>
										{data.documents.map((document) => {
											return (
												<ListGroup
													className='w-100 items-2'
													isHorizontal
													isFlush>
													<ListGroupItem className='fw-bold border-0 align-content-center'>
														{replaceDocumentType(document.type)}
													</ListGroupItem>
													<ListGroupItem className='border-0'>
														<Button
															size='sm'
															isOutline
															color='primary'
															icon='EyeFill'
															onClick={() =>
																handleFile(
																	document.onedrive_id,
																	document.type,
																)
															}>
															Lihat Dokumen
														</Button>
													</ListGroupItem>
												</ListGroup>
											);
										})}
									</AccordionItem>
								</Accordion>
							</div>
							<div className='col-12'>
								<Accordion
									className='text-center'
									id='AccordionFifth'
									activeItemId={false}>
									<AccordionItem
										id='AccordionItemBarcode'
										title='Barcode'
										icon='QrCode'>
										<QRCode
											id='SvgBarcode'
											className='img-fluid pb-3'
											value={data.unique_code}
										/>
										<div className='row'>
											<ListGroup
												className='w-100 items-2'
												isHorizontal
												isFlush>
												<ListGroupItem className='fw-bold border-0'>
													Kode Unik
												</ListGroupItem>
												<ListGroupItem className='border-0'>
													{data.unique_code}
												</ListGroupItem>
											</ListGroup>
										</div>
										<div className='row py-3'>
											<div className='col-6'>
												<Button
													className='w-100'
													isOutline
													color='primary'
													icon='Download'
													onClick={downloadUniqueCode}>
													Download
												</Button>
											</div>
											<div className='col-6'>
												<Button
													className='w-100'
													isOutline
													color='success'
													icon='Copy'
													onClick={copyUniqueCode}>
													Copy
												</Button>
											</div>
										</div>
									</AccordionItem>
								</Accordion>
							</div>
						</div>
					</>
				)}
				<FormCustom
					id={formOptions.id}
					type={formOptions.type}
					initialValues={formOptions.initialValues}
					isOpen={formOpen}
					setIsOpen={setFormOpen}
					title={formOptions.title}
					closeLabel={formOptions.closeLabel}
					submitLabel={formOptions.submitLabel}
					handleCustomSubmit={formOptions.submitFunction}
				/>

				<LoadingModal
					id='ModalLoading'
					loading={loadingModal}
					setLoading={setLoadingModal}
				/>

				{file && <FileModal file={file} isOpen={fileOpen} setIsOpen={setFileOpen} />}
			</Page>
		</PageWrapper>
	);
};

export default Dashboard;
