import { Store } from 'react-notifications-component';

const _settings = {
	insert: 'top',
	container: 'top-right',
	animationIn: ['animate__animated', 'animate__fadeIn'],
	animationOut: ['animate__animated', 'animate__fadeOut'],
	dismiss: {
		duration: 5000,
		pauseOnHover: true,
		onScreen: true,
		showIcon: true,
		waitForAnimation: true,
	},
};

/**
 * List of types:
 *
 * success
 * danger
 * info
 * default
 * warning
 *
 * @param title
 * @param message
 * @param type
 */
const showNotification = (title, message, type = 'default') => {
	Store.addNotification({
		title,
		message,
		type,
		..._settings,
	});
};

export default showNotification;
