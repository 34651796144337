import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import Iframe from 'react-iframe';
import { Document, Page, pdfjs } from 'react-pdf';
import Card, { CardBody } from '../bootstrap/Card';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../bootstrap/Modal';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const FileModal = ({ file, isOpen, setIsOpen }) => {
	const isImage = useMemo(() => !['pdf'].includes(file.extension), [file.extension]);
	const content = useMemo(() => {
		if (isImage) return <img className='img-fluid' src={file.src} alt={file.fileName} />;

		return window.innerWidth < 576 ? (
			<Document file={file.src}>
				<Page className='border shadow' pageNumber={1} />
			</Document>
		) : (
			<Iframe
				src={file.src}
				width='100%'
				height={`${window.innerHeight - 240}px`}
				display='initial'
				position='relative'
			/>
		);
	}, [file, isImage]);

	return (
		<Modal
			id='ModalFile'
			size={isImage || window.innerWidth < 576 ? null : 'xl'}
			isOpen={isOpen}
			setIsOpen={setIsOpen}
			// eslint-disable-next-line react/jsx-props-no-spreading
		>
			<ModalHeader setIsOpen={setIsOpen} className='p-4'>
				<ModalTitle id='preview-file-title'>{`${file.fileName}.${file.extension}`}</ModalTitle>
			</ModalHeader>
			<ModalBody>
				<Card shadow='none'>
					<CardBody className='text-center h-auto w-auto overflow-auto' isScrollable>
						{content}
					</CardBody>
				</Card>
			</ModalBody>
		</Modal>
	);
};
FileModal.propTypes = {
	file: PropTypes.oneOfType([PropTypes.object]).isRequired,
	isOpen: PropTypes.bool,
	setIsOpen: PropTypes.func,
};
FileModal.defaultProps = {
	isOpen: false,
	setIsOpen: () => {},
};

export default FileModal;
